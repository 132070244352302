import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { NotificationSnackbarProps } from "../assets/interfaces/interfaces";
import { AlertTitle, Portal } from "@mui/material";
import { ErrorRounded } from "@mui/icons-material";
import classNames from "classnames";

const NotificationSnackbar: React.FC<NotificationSnackbarProps> = ({
  open,
  onClose,
  title,
  message,
  severity,
  horizontal,
}) => {
  return (
    <Portal>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal }} // Position top-right
        className="md:w-[768px] h-[96px]"
      >
        <Alert
          icon={severity === "error" && <ErrorRounded className="self-center" />}
          severity={severity}
          sx={severity === "error" && { width: "100%" }}
          className={classNames({ "!text-components-alert-error !bg-components-alert-error-background": severity === "error" })}
        >
          {title ? <AlertTitle className="font-medium">{title}</AlertTitle> : null}
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default NotificationSnackbar;
