import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../helpers";
import dayjs from "dayjs";

type Series = {
  price: number;
  time: number;
};

type SparklineChartProps = {
  series: Series[];
  priceChange: number;
  height?: number;
  width?: number;
  isHoverable?: boolean;
};
const SparklineChart = ({ series, priceChange, height = 44, width = 90, isHoverable = false }: SparklineChartProps) => {
  const [isDecreasing, setIsDecreasing] = useState<boolean | null>(null);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);

  useEffect(() => {
    setIsDecreasing(priceChange < 0);
  }, [priceChange]);

  const strokeColor = isDecreasing
    ? isDarkMode
      ? "rgba(229, 115, 115, 1)"
      : "rgba(239, 83, 80, 1)"
    : isDarkMode
      ? "rgba(129, 199, 132, 1)"
      : "rgba(76, 175, 80, 1)";

  const dataPointFillColor = isDecreasing
    ? isDarkMode
      ? "rgba(244, 67, 54, 1)"
      : "rgba(211, 47, 47, 1)"
    : isDarkMode
      ? "rgba(102, 187, 106, 1)"
      : "rgba(46, 125, 50, 1)";

  const fillColors = isDecreasing
    ? ["rgba(239, 83, 80, 0.28)", "rgba(239, 83, 80, 0)"]
    : ["rgba(76, 175, 80, 0.28)", "rgba(76, 175, 80, 0)"];

  const dataPointStrokeColor = isDecreasing
    ? isDarkMode
      ? "rgba(229, 115, 115, 1)"
      : "rgba(239, 83, 80, 1)"
    : isDarkMode
      ? "rgba(129, 199, 132, 1)"
      : "rgba(76, 175, 80, 1)";

  const options: Highcharts.Options = {
    chart: {
      type: "area",
      height: height,
      width: width,
      spacing: [0, 0, 0, 0],
    },
    accessibility: {
      enabled: false,
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: isHoverable,
      useHTML: true,
      distance: 20,
      outside: true,
      backgroundColor: "rgba(97, 97, 97, 0.90)",
      padding: 0,
      formatter: function () {
        const formattedPrice = currencyFormatter(this.y);
        const formattedTime = dayjs(this.x).format("DD MMM, hh:mm A");
        return `
          <div class="sparkline-tooltip-content">
            <p class="sparkline-tooltip-price">${formattedPrice}</p>
            <p class="sparkline-tooltip-time">${formattedTime}</p>
          </div>
        `;
      },
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: isHoverable,
              lineWidth: 0,
            },
          },
        },
        fillOpacity: 0.1,
        lineWidth: 1.5,
        lineColor: strokeColor,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, fillColors[0]],
            [1, fillColors[1]],
          ],
        },
        states: {
          hover: {
            enabled: isHoverable,
            lineWidth: 2,
            fillColor: "rgba(0, 0, 0, 0.2)",
            lineColor: dataPointStrokeColor,
            halo: {
              size: 10,
              attributes: {
                fill: dataPointStrokeColor,
                width: 2,
              },
            },
          },
        },
        threshold: null,
        animation: {
          duration: 1200,
          easing: "cubic-bezier(0.62, 0.1, 0.18, 1)",
        },
      },
    },
    series: [
      {
        type: "area",
        name: "Price",
        data: series.map(({ time, price }) => [time, price]),
        color: dataPointFillColor,
        animation: {
          duration: 1200,
          easing: "cubic-bezier(0.62, 0.1, 0.18, 1)",
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { overflow: "hidden" } }}
    />
  );
};

export default SparklineChart;
