import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";
import withScrollToTop from "../../helpers/ScrollToTop";

function LegalDisclaimer() {
  return (
    <TermsAndPoliciesLayout type="disclaimer" headerTitle="Legal Disclaimer">
      <p className="font-['Inter'] font-bold text-lg text-[#1C1C1E] leading-[32px]">
        Subscriber Onboarding Disclaimer
      </p>
      <br />
      <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
        TradeAlgo (“the Company”) is a provider of financial subscription informational services.
        The Company is not a broker-dealer and not an investment adviser under applicable securities
        laws, and it is not registered with the Securities and Exchange Commission (“SEC”) as a
        Registered Investment Adviser (“RIA”). All content, tools, and information provided through
        TradeAlgo’s platform are strictly for
        {" "}
        <b>informational and educational</b>
        {" "}
        purposes. None of the
        services or materials offered by TradeAlgo should be interpreted as personalized investment,
        financial, legal, or tax advice. The Company does not evaluate any particular investment's
        suitability or risk profile for individual subscribers.
        {" "}
        <b>
          Any trading or investment decisions
          you make are your own responsibility
        </b>
        {" "}
        and should be based on an independent evaluation of your
        financial circumstances and objectives.
      </p>
      <br />
      <p className="font-['Inter'] font-bold text-lg text-[#1C1C1E] leading-[32px]">
        Paper Trading App Disclaimer
      </p>
      <br />
      <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
        The paper trading application offered by TradeAlgo is intended solely to
        {" "}
        <b>
          demonstrate
          hypothetical trading strategies
        </b>
        {" "}
        for educational use.
        {" "}
        <b>
          It does not provide and should not
          be construed as financial, investment, or trading advice
        </b>
        . TradeAlgo is not a broker-dealer
        or investment adviser under applicable securities laws. It is not registered with the
        Securities and Exchange Commission (“SEC”) as a Registered Investment Adviser (“RIA”), and
        any examples of trades, past performance results, or risk-reward scenarios presented
        within the application are entirely
        {" "}
        <b>theoretical</b>
        {" "}
        and do not guarantee any future results.
        Subscribers are advised to perform their due diligence and consult qualified professionals
        before making investment decisions. All information and tools provided are for
        {" "}
        <b>illustration</b>
        only, and TradeAlgo is not liable for any actions taken based on such information.
      </p>
      <br />
      <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
        General Disclaimer
      </p>
      <br />
      <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
        Founded in 2019,
        {" "}
        <b>TradeAlgo</b>
        {" "}
        is an industry-leading, subscription-based financial
        information service designed to help subscribers make sense of today’s dynamic
        markets. We bridge the gap between raw data and actionable insights by delivering
        comprehensive research, in-depth analytics, and timely commentary on a wide range
        of financial instruments.
        {" "}
        <b>TradeGPT</b>
        , our AI-powered research companion, leverages
        advanced language models to gather, process, and interpret information on
        financial-related companies. While neither TradeAlgo nor TradeGPT function
        as broker-dealers or registered investment advisors, both platforms serve as invaluable
        resources for investors seeking reliable market intelligence, helping them stay informed
        and make more confident decisions in an ever-evolving financial landscape.
      </p>
    </TermsAndPoliciesLayout>
  );
}
export default withScrollToTop(LegalDisclaimer);
