import React from "react";

export default function LastUpdatedInfo() {
  return (
    <div>
      <div className="font-['Inter'] font-semibold text-lg text-[#1C1C1E] leading-[24px]">
        Last Updated
      </div>
      <div className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[24px]">
        17 March 2025
      </div>
    </div>
  );
}
