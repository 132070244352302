import {
  CallEffect,
  CancelEffect,
  ForkEffect,
  TakeEffect,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  take,
  all,
  select,
} from "redux-saga/effects";
import { eventChannel, END, Task } from "redux-saga";
import { finchatMessageLoad, finchatMessageLoadSuccess, finchatMessageLoadFailure, finchatMessageChartLoadSuccess, finchatMessageCancel, finchatMessageCancelAll } from "../slices/finchatSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { FinchatMessagePayload } from "../../assets/interfaces/interfaces";
import axios from "axios";
import { fetchActivePlan, updatePromptsLeft } from "../slices/activePlanSlice";
import { AllPlanState } from "../../assets/interfaces/interfaces";
import { updateLatestSummaryFromLLM } from "../slices/newsSummarySlice";
function fetchWithTimeout(url: string, options, timeout = 60000) { // Default timeout set to 30 seconds
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timed out")), timeout),
    ),
  ]);
}
function createEventChannel(stream: ReadableStream) {
  return eventChannel((emitter) => {
    const reader = stream.getReader();
    const read = () => {
      reader.read().then(({ done, value }) => {
        if (done) {
          emitter(END);
          return;
        }
        if (value) {
          const messageToEmit = new TextDecoder().decode(value);
          emitter(messageToEmit);
        }
        read();
      }).catch((error) => {
        emitter(error instanceof Error ? error.message : "An unknown error occurred");
        emitter(END);
      });
    };

    read();

    return () => {
      reader.cancel();
    };
  });
}
// Utility function to check the plan status
const getPlanStatus = (state: { plan: AllPlanState }) => state.plan.status;
function* waitForPlanSuccess() {
  let status = yield select(getPlanStatus);
  let attempts = 0; // Counter for attempts
  while (status !== "succeeded" && attempts < 5) {
    if (status === "idle" || status === "failed") {
      yield put(fetchActivePlan());
      attempts++; // Increment the attempt counter
    }
    yield delay(1000); // Check every 1 second
    status = yield select(getPlanStatus);
  }
  if (attempts >= 5) {
    yield put(finchatMessageLoadFailure());
    // For example, log an error, update the state with an error message, etc.
    console.error("Failed to fetch active plan after 5 attempts.");
  }
}

function* fetchNewsSummary(user_id: string, chat_id: string, session_id: string, user_query: string) {
  try {
    const response = yield call(
      fetchWithTimeout,
      `${process.env.REACT_APP_PYTHON_APi_URL}/v1/news_cards`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "platform": "web",
        },
        credentials: "include",
        body: JSON.stringify({ user_id, chat_id, session_id, user_query }),
      },
      60000,
    );

    if (!response.ok) {
      throw new Error("Failed to fetch news summary");
    }

    const data = yield response.json();
    if (Array.isArray(data.news_summary) && data.news_summary.length > 0) {
      const payload = {
        [session_id]: {
          chat_id,
          news_summary: data.news_summary,
        },
      };
      yield put(updateLatestSummaryFromLLM(payload));
    }
  }
  catch (error) {
    console.error("Error fetching news cards:", error);
  }
}

function* fetchFinchatMessage(action: PayloadAction<FinchatMessagePayload>) {
  const user_query = action.payload.input;
  const { user_id, chat_id, session_id, input_type, attachments } = action.payload;
  yield call(waitForPlanSuccess);
  // active plan endpoint has succeeded
  try {
    yield call(fetchNewsSummary, user_id, chat_id, session_id, user_query);
    const query: Partial<FinchatMessagePayload & { user_query: string }> = {
      session_id,
      user_query,
      chat_id,
      user_id,
      input_type,
    };
    if (attachments) {
      query.attachments = attachments;
    }
    const response = yield call(
      fetchWithTimeout,
      `${process.env.REACT_APP_PYTHON_APi_URL}/v1/stream`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "platform": "web",
        },
        credentials: "include",
        body: JSON.stringify(query),
      },
      60000,
    );
    if (!response.body) {
      throw new Error("No response body");
    }
    const channel = yield call(createEventChannel, response.body);
    try {
      while (true) {
        const data = yield take(channel);
        const payload = {
          outputLoading: true,
          message: data as string,
          chat_id,
          input: user_query,
          session_id,
        };
        yield put(finchatMessageLoadSuccess(payload));
      }
    }
    finally {
      if (yield cancelled()) {
        if (channel) {
          channel.close();
        }
      }
      const payload = {
        outputLoading: false,
        message: "",
        chat_id,
        input: user_query,
        session_id,
      };
      yield put(finchatMessageLoadSuccess(payload));
      // call to fetch chart data after stream is finished
      let chartResponse;
      for (let i = 0; i < 5; i++) {
        chartResponse = yield call(axios.post, `${process.env.REACT_APP_PYTHON_APi_URL}/v1/get_stream_output`, query, {
          headers: {
            // 'Authorization': `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });
        if (chartResponse.status !== 404) {
          break;
        }
        if (i < 4) { // Add a delay for retries, but not after the last attempt
          yield delay(500); // 0.5 seconds delay
        }
      }
      const chart_data = chartResponse.data.chart_data;
      const chart_flag = chartResponse.data.chart_flag;
      const prompt_left = chartResponse.data.prompt_left;
      const list_of_tickers = chartResponse.data.list_of_tickers;
      const list_of_recommendations = chartResponse.data.list_of_recommendations;
      const news_card = chartResponse.data.news_card;
      const updated_news_summary = news_card?.news_summary;

      const actions = [
        put(finchatMessageChartLoadSuccess({ chart_data: chart_data, chat_id, chart_flag, list_of_recommendations, session_id, list_of_tickers })),
        put(updatePromptsLeft(prompt_left)),
      ];

      yield all(actions);
      // Only dispatch updateLatestSummaryFromLLM if updated_news_summary exists
      if (updated_news_summary?.length) {
        const newsPayload = {
          [session_id]: {
            chat_id,
            news_summary: updated_news_summary,
          },
        };
        yield put(updateLatestSummaryFromLLM(newsPayload));
      }
    }
  }
  catch {
    yield put(finchatMessageLoadFailure());
  }
}

function* watchFinchatMessages(): Generator<TakeEffect | ForkEffect | CallEffect | CancelEffect, void, unknown> {
  let lastTask: Record<string, Task | null> = {};

  while (true) {
    const action = yield take([
      finchatMessageLoad.type,
      finchatMessageCancel.type,
      finchatMessageCancelAll.type,
      finchatMessageLoadSuccess.type,
    ]);

    if (!action || typeof action !== "object" || !("type" in action)) return;

    if (action.type === finchatMessageLoad.type) {
      const action2 = action as PayloadAction<FinchatMessagePayload>;
      lastTask[action2.payload?.session_id] = (yield fork(fetchFinchatMessage, action2)) as Task | null;
    }

    if (action.type === finchatMessageCancel.type) {
      const action2 = action as PayloadAction<string>;
      if (action2.payload && lastTask[action2.payload]) {
        yield cancel(lastTask[action2.payload]);
        lastTask[action2.payload] = null;
      }
    }

    if (action.type === finchatMessageCancelAll.type) {
      for (const sessionId in lastTask) {
        if (Object.prototype.hasOwnProperty.call(lastTask, sessionId) && lastTask[sessionId]) {
          cancel(lastTask[sessionId]);
        }
      }
      lastTask = {};
    }

    if (action.type === finchatMessageLoadSuccess.type) {
      const action2 = action as PayloadAction<{
        session_id: string;
        message: string;
        outputLoading: boolean;
      }>;

      if (!action2.payload?.outputLoading) {
        lastTask[action2.payload.session_id] = null;
      }
    }
  }
}

export default function* finchatSaga() {
  yield fork(watchFinchatMessages);
}
