import { Drawer } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "../tradegpt.scss";
import newChatIcon from "../../assets/images/finchatGPT/newChatIcon.png";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { RootState, SidebarProps } from "../../assets/interfaces/interfaces";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import taLogo from "../../assets/images/finchatGPT/taLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, requestSignOut } from "../auth/authActions";
import SignInModal from "../auth/SignInModal";
import SettingsModal from "../SettingsModal";
import {
  clearMessages,
  fetchPastChats,
} from "../../redux/slices/pastChatsSlice";
import { AppDispatch } from "../../redux/store";
import { fetchPastSession } from "../../redux/slices/pastSessionSlice";
import { useParams } from "react-router-dom";
import SideMenuItem from "./SideMenuItem";
import ContactSupportModal from "../ContactModal";
import {
  clearFinchatData,
  finchatMessageCancelAll,
} from "../../redux/slices/finchatSlice";
import PlanModal from "../Plans/PlanModal";
import { filterChats, findLongestName } from "../../helpers";
import PromptBook from "../../assets/images/finchatGPT/book.png";
import classNames from "classnames";
import {
  fetchActivePlan,
  resetPlanStatus,
} from "../../redux/slices/activePlanSlice";
import { getAuth, getIdToken } from "firebase/auth";
import Cookies from "universal-cookie";
import debounce from "lodash/debounce";
import SidebarPromoModal from "../SidebarPromoModal";
import useEnhancedNavigate, {
  extractParams,
  urlIsAppScheme,
} from "../Session/helpers";
import { fetchToken } from "../../redux/slices/fetchAuthToken";
import { fetchUser } from "../../redux/slices/userSlice";
import { resetValidUserState } from "../../redux/slices/validateUserSlice";
import { resetState } from "../../redux/slices/deleteUserSlice";
import IntroIcon from "../../assets/images/finchatGPT/Intro.png";
import { hidePromo } from "../../redux/slices/IntroPromoSlice";
import { fetchLogs, setLastViewed } from "../../redux/slices/changeLogsSlice";

import SearchIcon from "../../assets/images/finchatGPT/search-outline.png";
import DashboardMenuIcon from "../../assets/images/finchatGPT/dashboard-menu-icon.png";
import LikeMenuIcon from "../../assets/images/finchatGPT/Thumbs-up.png";
import PreferencesModal from "./PreferencesModal";
import RecommendedPromptsModal from "../RecommendedPromptsModal";
import { NewWindowIcon } from "../Plans/icons/GPTIcons/NewWindowIcon";
import ChangeLogModal from "../ChangeLogModal";
import { logClickEvent, setGAUser } from "../../analytics";
import { DashboardIcon } from "../../assets/icons/DashboardIcon";
import { ConfidentialsContext, SidebarStates } from "../../constants";

const cookies = new Cookies();
const Sidebar: React.FC<SidebarProps> = ({
  handleNewChat,
  drawerOpen,
  toggleDrawer,
  isMobile,
  signInModalOpen,
  setSignInModalOpen,
  flow,
  setFlow,
  messages,
  setMessages,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [source, setSource] = useState(""); // State to store the extracted parameters
  const navigate = useEnhancedNavigate();
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const { session_id } = useParams();
  const [generalActive, setGeneralActive] = useState(true);
  const tokenStatus = useSelector((state: { token }) => state.token.status);

  const clearAllChatData = () => {
    setMessages((allMessages) => {
      return {
        ...allMessages,
        [session_id]: [],
      };
    });
    handleProfileMenuClose();
    dispatch(clearFinchatData());
    dispatch(clearMessages());
    navigate("/");
  };

  const handleSignOut = () => {
    dispatch(resetState());
    dispatch(resetValidUserState());
    dispatch(requestSignOut());
    dispatch(clearUser());
    dispatch(resetPlanStatus());
    // Lets force to cancel a message if there is any loading
    dispatch(finchatMessageCancelAll());
    clearAllChatData();
  };

  const user = useSelector((state: RootState) => state.auth.currentUser);
  const authLoading = useSelector((state: RootState) => state.auth.loading);
  const showIntroPromo = useSelector(
    (state: { introPromo }) => state.introPromo.show,
  );
  const finchatLoading = useSelector(
    (state: RootState) => state.finchat.loading,
  );
  const userPreferences = useSelector(
    (state: { userPreferences }) => state.userPreferences,
  );
  const auth = getAuth();
  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const { messages: chatMessages } = useSelector(
    (state: RootState) => state.chat,
  );

  const plan = useSelector((state: { plan }) => state.plan.plan);
  useEffect(() => {
    const paramsSource = extractParams(window.location.search);
    setSource(paramsSource);
  }, []);

  useEffect(() => {
    if (user) {
      const { userId } = user;
      if (
        messages?.length === 2
        && !finchatLoading
        && tokenStatus === "succeeded"
      ) {
        dispatch(fetchPastChats({ userId }));
      }
    }
  }, [user, messages?.length, dispatch, finchatLoading, tokenStatus]);
  useEffect(() => {
    if (!!user && !chatMessages.length && tokenStatus === "succeeded") {
      const { userId } = user;
      dispatch(fetchPastChats({ userId }));
    }
  }, [user, dispatch, chatMessages.length, tokenStatus]);
  const chatMessagesMemo = useMemo(() => {
    return searchTerm?.length
      ? chatMessages.filter(item =>
        item?.title
          ?.toLocaleLowerCase()
          ?.includes(searchTerm?.toLocaleLowerCase()),
      )
      : chatMessages;
  }, [searchTerm, chatMessages]);
  const filteredChats = filterChats(chatMessagesMemo);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedAnchor, setNestedAnchor] = useState(null);
  const [userAvatar, setUserAvatar] = useState("");
  useEffect(() => {
    if (!isUserSignedIn || !user?.email)
      return setUserAvatar("https://gravatar.com/avatar/?s=200&d=mp");
    if (user?.photoURL) return setUserAvatar(user.photoURL);

    const encoder = new TextEncoder();
    const data = encoder.encode(user.email);
    crypto.subtle.digest("SHA-256", data).then((hash) => {
      const hashString = Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
      setUserAvatar(
        `https://www.gravatar.com/avatar/${hashString}?s=200&d=identicon`,
      );
    });
  }, [user?.email, user?.photoURL, isUserSignedIn]);

  useEffect(() => {
    if (isUserSignedIn && user.userId) {
      setGAUser(user.userId);
    }
  }, [isUserSignedIn, user]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSupportMenuOpen = (event) => {
    setNestedAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    setNestedAnchor(null);
  };

  const handleSupportClose = () => {
    setNestedAnchor(null);
  };

  const handleLoggingSearch = useCallback(
    debounce((keywords) => {
      logClickEvent("search_click", { search_keywords: keywords });
    }, 3000),
    [],
  );

  const keys = Object.keys(filteredChats);

  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);

  const togglePreferencesModal = () => {
    setIsPreferencesModalOpen(!isPreferencesModalOpen);
    setGeneralActive(true);
  };

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
    setGeneralActive(true);
  };

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [promoModal, setPromoModal] = useState(false);
  const toggleContactModal = () => {
    setIsContactModalOpen(!isContactModalOpen);
  };
  useEffect(() => {
    if (tokenStatus === "succeeded" && session_id !== undefined) {
      dispatch(fetchPastSession({ sessionId: session_id }));
    }
  }, [session_id, dispatch, tokenStatus]);

  const [planModalOpen, setPlanModalOpen] = useState(false);
  const authStatus = useSelector((state: { user }) => state.user.status);
  const activePlanStatus = useSelector((state: { plan }) => state.plan.status);
  const currentUser = auth.currentUser;
  useEffect(() => {
    if (
      currentUser
      && !user
      && (authStatus === "failed" || activePlanStatus === "failed")
      && authStatus !== "loading"
      && activePlanStatus !== "loading"
    ) {
      // fetch token
      const redirect = window.location.search.split("redirect_uri=")[1] ?? "/";
      const isMobileApp = urlIsAppScheme(redirect);
      if (tokenStatus === "idle") {
        getIdToken(auth.currentUser, true).then((token) => {
          dispatch(
            fetchToken({
              token,
              isMobileApp, // If it was an in-app browser call, we will get the token on the response.
            }),
          );
        });
      }
      else {
        if (tokenStatus === "succeeded") {
          dispatch(fetchUser({ source }));
          dispatch(fetchActivePlan());
        }
      }
    }
  }, [currentUser, user, authStatus, activePlanStatus, tokenStatus, source]);
  const { sidebarState } = useContext(ConfidentialsContext);

  useEffect(() => {
    if (showIntroPromo) {
      setPromoModal(true);
    }
  }, [showIntroPromo]);
  const longestName = useMemo(() => {
    return findLongestName(user);
  }, [user]);

  const { logs: changeLogs, lastViewed } = useSelector((state: { changeLogs }) => state.changeLogs);

  const [showChangeLogModal, setShowChangeLogModal] = useState(false);

  useEffect(() => {
    dispatch(fetchLogs());
  }, []);

  useEffect(() => {
    if (changeLogs.length > 0 && changeLogs[0].version !== lastViewed) {
      setShowChangeLogModal(true);
    }
  }, [changeLogs, lastViewed]);

  const handleChangeLogModalClose = () => {
    setShowChangeLogModal(false);
    const version = changeLogs[0].version;
    dispatch(setLastViewed(version));
    cookies.set("changeLogs-lastViewed", version, { path: "/" });
  };

  const [recommendedPromptsModalOpened, setRecommendedPromptsModalOpened]
    = useState(false);
  // Function to determine which sidebar item to render based on the current state
  const renderSidebarItem = () => {
    switch (sidebarState) {
      case SidebarStates.Intro:
        return (
          <a
            onClick={() => {
              logClickEvent("tgpt_intro_click");
              setPromoModal(true);
            }}
            className="flex min-h-[44px] py-1 cursor-pointer text-sm dark:hover:bg-token-surface-primary hover:bg-token-surface-primary rounded-lg px-2"
          >
            <span className="flex w-full justify-between items-center">
              <div className="flex items-center">
                <img
                  src={IntroIcon}
                  alt="PromptBook"
                  className="h-7 w-auto pr-2"
                />
                <span className="font-semibold text-white text-[14px]">
                  Intro to Tradegpt
                </span>
                <span className="bg-[#34C759] text-white text-xs p-[2px_6px] rounded-md ml-3">
                  GUIDE
                </span>
              </div>
            </span>
          </a>
        );
      case SidebarStates.Prompt:
        return (
          <a
            onClick={() => setPromoModal(true)}
            className="flex min-h-[44px] py-1 cursor-pointer text-sm dark:hover:bg-token-surface-primary hover:bg-token-surface-primary rounded-lg px-2"
          >
            <span className="flex w-full justify-between items-center">
              <div className="flex items-center">
                <img
                  src={PromptBook}
                  alt="PromptBook"
                  className="h-5 w-auto pr-3"
                />
                <span className="font-semibold text-white text-[14px]">
                  TradeGPT 100+ Prompts
                </span>
              </div>
            </span>
          </a>
        );
      default:
        return (
          <div className="group relative h-9 rounded-lg bg-token-surface-primary animate-pulse"></div>
        );
    }
  };
  return (
    <div
      className={classNames("flex-shrink-0 overflow-x-hidden bg-black", {
        hidden: !drawerOpen,
      })}
    >
      {/* Modals */}
      {!!changeLogs.length && (
        <ChangeLogModal
          open={showChangeLogModal}
          handleClose={handleChangeLogModalClose}
          log={changeLogs[0]}
        />
      )}
      <RecommendedPromptsModal
        open={recommendedPromptsModalOpened}
        handleClose={() => setRecommendedPromptsModalOpened(false)}
        onLimitReached={() => setPlanModalOpen(true)}
      />
      <SignInModal
        flow={flow}
        setFlow={setFlow}
        open={signInModalOpen}
        setOpen={setSignInModalOpen}
      />
      <PlanModal
        open={planModalOpen}
        handleClose={() => setPlanModalOpen(false)}
      />
      <SidebarPromoModal
        handleClose={() => {
          dispatch(hidePromo());
          setPromoModal(false);
        }}
        open={promoModal || showIntroPromo}
        promo={sidebarState}
      />
      <Drawer
        // classes={{ paper: "custom-height" }}
        classes={{ paper: "dark" }}
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer}
        className="sidebar h-full w-[260px]"
        anchor="left"
      >
        <div className="flex h-full min-h-0 flex-col">
          <div className="flex h-full min-h-0 flex-col transition-opacity opacity-100">
            <div className="scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20">
              <nav
                className="flex h-full w-full flex-col px-3 pb-3.5"
                aria-label="Chat history"
              >
                <div className="sticky left-0 right-0 top-0 z-20 bg-black pt-3.5">
                  <div className="pb-0.5 last:pb-0" tabIndex={0}>
                    <button
                      onClick={() => {
                        logClickEvent("new_session_click");
                        handleNewChat();
                      }}
                      disabled={finchatLoading}
                      className="w-full cursor-pointer group flex select-none min-h-10 items-center gap-2 rounded-lg px-2 font-medium hover:bg-token-surface-primary press-effect"
                    >
                      <div className="h-7 w-7 flex-shrink-0">
                        <img
                          src={taLogo}
                          alt="TradeAlgo Logo"
                          className="sidebar-logo"
                        />
                      </div>
                      <div className="text-left grow overflow-hidden text-ellipsis whitespace-nowrap text-token-text-primary">
                        TradeGPT
                      </div>
                      <div className="flex">
                        <img
                          className="w-5 h-5"
                          src={newChatIcon}
                          alt="Edit Icon"
                        />
                      </div>
                    </button>
                  </div>
                </div>

                <div className="tradegpt-search-field-wrapper">
                  <input
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      handleLoggingSearch(e.target.value);
                    }}
                    type="text"
                    placeholder="Search"
                    className="tradegpt-search-bar"
                  />
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="tradegpt-search-icon"
                  />
                </div>

                <div
                  className="tradegpt-dashboard-menu-item"
                  onClick={() => navigate("/dashboard")}
                >
                  <img
                    src={DashboardMenuIcon}
                    alt="Dashboard Menu Icon"
                    className="tradegpt-dashboard-menu-icon"
                  />
                  Dashboard
                </div>

                <div
                  className="tradegpt-dashboard-menu-item"
                  onClick={() => navigate("/performance-dashboard")}
                >
                  <div className="flex items-center gap-x-3">
                    <DashboardIcon />
                    Performance Dashboard
                  </div>
                </div>

                <div
                  className="tradegpt-dashboard-menu-item"
                  onClick={() => setRecommendedPromptsModalOpened(true)}
                >
                  <img
                    src={LikeMenuIcon}
                    alt="Like Menu Icon"
                    className="tradegpt-dashboard-menu-icon"
                  />
                  Recommended Prompts
                </div>

                <div className="my-5 flex-col flex-1 transition-opacity duration-500 -mr-2 pr-2 overflow-x-hidden overflow-y-auto scroll-container">
                  <div className="flex flex-col gap-2 pb-2 text-token-text-primary text-sm">
                    <div>
                      <span className="space-y-5">
                        {/* Group */}
                        {authLoading
                          ? (
                              <div className="relative">
                                <div className="select-none">
                                  <h3 className="h-9 pb-2 pt-3 px-2 text-xs font-medium text-ellipsis overflow-hidden break-all bg-white dark:bg-black text-token-text-tertiary">
                                    Loading...
                                  </h3>
                                </div>
                                <ol className="space-y-1">
                                  {Array.from({ length: 10 }).map((_, index) => (
                                    <li
                                      key={index}
                                      className="group relative h-9 rounded-lg bg-token-surface-primary animate-pulse"
                                    >
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            )
                          : (
                              !!isUserSignedIn
                              && keys.map((name) => {
                                if (filteredChats[name].length) {
                                  return (
                                    <div className="relative" key={name}>
                                      <div className="select-none">
                                        <h3 className="h-9 pb-2 pt-3 px-2 text-xs font-medium text-ellipsis overflow-hidden break-all bg-white dark:bg-black text-token-text-tertiary">
                                          {name}
                                        </h3>
                                      </div>
                                      <ol className="space-y-1">
                                        {filteredChats[name].map(
                                          (item: { id; sessionId }, index) => (
                                            <SideMenuItem
                                              key={item.id || index}
                                              clearAllChatData={clearAllChatData}
                                              item={item}
                                            />
                                          ),
                                        )}
                                      </ol>
                                    </div>
                                  );
                                }
                                return null;
                              })
                            )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Footer */}
                <div className="flex flex-col pt-2 border-t empty:hidden dark:border-white/20">
                  {renderSidebarItem()}
                  <div className="flex w-full items-center">
                    <div className="grow">
                      <div className="group relative">
                        <button
                          className="flex w-full items-center gap-2 rounded-lg p-2 text-sm hover:bg-gray-100 hover:bg-token-surface-primary group-ui-open:bg-gray-100 group-ui-open:bg-token-surface-primary dark:hover:bg-token-surface-primary dark:group-ui-open:bg-gray-800 dark:group-ui-open:bg-token-surface-primary"
                          disabled={authLoading}
                          onClick={event =>
                            isUserSignedIn
                              ? handleProfileMenuOpen(event)
                              : setSignInModalOpen(true)}
                          type="button"
                        >
                          <div className="flex-shrink-0">
                            <div className="flex items-center justify-center rounded-full">
                              <div className="relative flex ">
                                <img
                                  alt="User"
                                  loading="eager"
                                  width="32"
                                  height="32"
                                  decoding="async"
                                  className={classNames("rounded-full", {
                                    "animate-pulse": authLoading,
                                  })}
                                  src={userAvatar}
                                />
                                {userPreferences.hasOpenedModal === false && (
                                  <div className="absolute top-0 right-0 h-2 w-2 rounded-full bg-[#E65100]"></div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-token-text-secondary">
                            <div
                              className={classNames(
                                "font-semibold transition-all duration-300",
                                {
                                  "text-transparent bg-token-text-primary animate-pulse rounded":
                                    authLoading,
                                },
                              )}
                            >
                              {isUserSignedIn ? longestName : "Sign In"}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Menu's */}

        <Menu
          className="menulist-home body1"
          // classes={{ paper: 'menu-paper' }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleProfileMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              style: {
                transform: "translateY(-4%)",
              },
            },
          }}
        >
          <MenuItem
            className="submenu-item"
            onClick={() => {
              // const { userId } = user
              // dispatch(fetchPlaidLink({userId}))
              // navigate('/plans')
              logClickEvent("my_plan_click");
              setPlanModalOpen(true);
            }}
          >
            <div>My Plan</div>
            {plan?.subscription_type !== "Premium" && (
              <div className="w-[75px] rounded-[100px] text-[13px] text-white bg-mui-primary-blue-light dark:bg-mui-primary-blue-dark text-center px-[3px]">
                Upgrade
              </div>
            )}
          </MenuItem>

          <MenuItem onClick={handleSupportMenuOpen} className="submenu-item">
            <div>Support</div>
            <ListItemIcon className="ps-4">
              <ChevronRightIcon className="right-arrow text-mui-black-87 dark:text-white" />
            </ListItemIcon>
          </MenuItem>

          <PreferencesModal
            open={isPreferencesModalOpen}
            setOpen={setIsPreferencesModalOpen}
          />

          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("preference_click");
              togglePreferencesModal();
            }}
          >
            <div>Preferences</div>
            {userPreferences.hasOpenedModal === false && (
              <div className="rounded-full bg-[#FB8C00] h-2 w-2"></div>
            )}
          </MenuItem>

          <SettingsModal
            clearAllChatData={clearAllChatData}
            handleProfileMenuClose={handleProfileMenuClose}
            generalActive={generalActive}
            setGeneralActive={setGeneralActive}
            open={isSettingsModalOpen}
            setOpen={setIsSettingsModalOpen}
            handleSignOut={handleSignOut}
          />
          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("setting_click");
              toggleSettingsModal();
            }}
          >
            <div>Settings</div>
          </MenuItem>
          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("logout_click");
              handleSignOut();
            }}
          >
            <div>Log Out</div>
          </MenuItem>
        </Menu>
        {/* Support nested menu */}
        <Menu
          className="nested-menulist-home body1"
          anchorEl={nestedAnchor}
          open={Boolean(nestedAnchor)}
          onClose={handleSupportClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <ContactSupportModal
            open={isContactModalOpen}
            handleClose={toggleContactModal}
          />
          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("contact_support_click");
              toggleContactModal();
            }}
          >
            <div className="">Contact Support</div>
          </MenuItem>
          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("faq&help_click");
              navigate("/faq");
            }}
          >
            FAQ & Help Center
            <NewWindowIcon className="dark:fill-white fill-black" />
          </MenuItem>
          <MenuItem
            className="submenu-item"
            onClick={() => {
              logClickEvent("terms&policy_click");
              navigate("/policies");
            }}
          >
            Terms and Policies
            <NewWindowIcon className="dark:fill-white fill-black" />
          </MenuItem>
          <MenuItem
            className="nested-submenu-item"
            onClick={() => navigate("/release-notes")}
          >
            Release Notes
          </MenuItem>
        </Menu>
      </Drawer>
    </div>
  );
};

export default Sidebar;
