export enum MODE {
  VIEW = "view",
  SEARCH = "search",
  LIST_MANAGEMENT = "listManagement",
  NEWS = "news",
}

export enum PROMPT {
  PRICE_CHART = "Price Chart",
  RECENT_NEWS = "Recent News",
  TRADE_IDEAS = "Trade Ideas",
  ANALYSIS = "Analysis",
}

export const PromptList = [
  PROMPT.PRICE_CHART,
  PROMPT.RECENT_NEWS,
  PROMPT.TRADE_IDEAS,
  PROMPT.ANALYSIS,
];

export const getPrompt = (type: PROMPT, tickerName: string) => {
  switch (type) {
    case PROMPT.PRICE_CHART:
      return `Generate a price chart for the ticker ${tickerName}. Please include the price movements and key trends.
`;
    case PROMPT.RECENT_NEWS:
      return `Provide the recent news articles related to the ticker ${tickerName}. Include a summary of each article and its publication date.
`;
    case PROMPT.TRADE_IDEAS:
      return `Perform fundamental analysis on ${tickerName}. Highlight key financial metrics and any upcoming events that could impact the stock. Give me top 3 options that are linked to ${tickerName}.`;
    case PROMPT.ANALYSIS:
      return `Conduct a fundamental analysis of ${tickerName}. Discuss key financial metrics, earnings reports, and any relevant industry trends.`;
    default:
      throw Error(
        `wrong prompt type ${type}. Please provide a valid prompt type`,
      );
  }
};

export enum PANELS {
  WATCHLIST = "watchlist",
  TODAYSUMMARY = "todaysummary",
}
