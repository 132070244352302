import { useDispatch, useSelector } from "react-redux";
import { LLMSummaryNews } from "../../assets/interfaces/interfaces";
import classNames from "classnames";
import { daysSince } from "../../helpers";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { AppDispatch } from "../../redux/store";
import { showNotification } from "../../redux/slices/notificationSlice";
import { setWatchlistDrawerState } from "../../redux/slices/watchlistSlice";
import { updateSelectedTickerProps } from "../../redux/slices/newsSummarySlice";

type NewsSummaryCardType = {
  newsSummary: LLMSummaryNews;
  allExpired: boolean;
};
export default function NewsSummaryCard({ newsSummary, allExpired }: NewsSummaryCardType) {
  const {
    selectedTickerProps,
  } = useSelector((state: { newsSummary }) => state.newsSummary);

  const isExpired = daysSince(newsSummary.create_timestamp) > 7;

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div
      onClick={() => {
        if (isExpired) {
          dispatch(
            showNotification({
              title: "News Summary Unavailable",
              message: "The news summary you selected has expired and was only accessible for up to 7 days from its creation date.",
              severity: "error",
              horizontal: "center",
            }),
          );
        }
        else {
          dispatch(setWatchlistDrawerState(true));
          dispatch(
            updateSelectedTickerProps({
              ticker: newsSummary.ticker,
              isExpandKeyEvents: true,
            }),
          );
        }
      }}
      className={classNames(
        "h-full gradient-border-box p-4 rounded-lg flex flex-col cursor-pointer",
        {
          "selected bg-gradient-blue-deepOrange-states-selected": selectedTickerProps.ticker === newsSummary.ticker,
          "bg-tgpt-secondary-accentLight hover:bg-gradient-blue-deepOrange-states-hover": selectedTickerProps.ticker !== newsSummary.ticker,
        },
      )}
    >
      <div className="flex justify-between items-start">
        <p className="caption text-mui-black-60 dark:text-mui-white-70 pb-2">{newsSummary.ticker}</p>
        {isExpired && !allExpired && <WarningRoundedIcon className="!h-[11px] !w-[12px] !fill-warning-main-dark" />}
      </div>
      <p className="body2 overflow-hidden line-clamp-4">{newsSummary.summarized_headline}</p>
    </div>
  );
}
