import { List, ListItem, ListItemText } from "@mui/material";
import withScrollToTop from "../../helpers/ScrollToTop";
import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";

function TermsOfService() {
  return (
    <TermsAndPoliciesLayout type="service" headerTitle="Terms of Service">
      <p className="font-bold text-[28px]">1. Introduction</p>
      <br />
      Welcome to TA Fintech Inc., where innovation meets interaction. These
      Terms of Service govern your use of our cutting-edge chatbot, TradeGPT,
      providing you with a unique and dynamic conversational experience. Please
      take a moment to carefully read and understand the following terms before
      engaging with our service.
      <br />
      <br />
      {" "}
      At TA Fintech Inc., we are committed to fostering an environment
      that encourages creativity, open dialogue, and positive engagement.
      TradeGPT is designed to be a tool that enhances your interaction with
      technology, offering a seamless and intelligent conversation experience.
      <br />
      <br />
      <p className="font-bold text-[28px]">2. Interpretation</p>
      <br />
      (a)
      {" "}
      <b>Definitions:</b>
      {" "}
      In these Terms of Service, unless the context
      requires otherwise, the following terms shall have the meanings ascribed
      to them below:
      <br />
      <List sx={{ listStyleType: "lower-roman", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <b>"API"</b>
                {" "}
                refers to the Application Programming Interface provided by TA Fintech Inc.
                for accessing and interacting with TradeGPT.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <b>"Product Name"</b>
                {" "}
                refers to the innovative chatbot developed
                and maintained by TA Fintech Inc., as described in these Terms of Service.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <b>"You"</b>
                {" "}
                and
                <b>"User"</b>
                {" "}
                refer to any individual or
                entity accessing or using TradeGPT or the API.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <b>"TA Fintech Inc."</b>
                {" "}
                refers to the entity or organization
                providing TradeGPT, as specified in these Terms of Service.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      (b)
      {" "}
      <b>Plural and Singular:</b>
      {" "}
      Unless the context otherwise requires,
      words in the singular shall include the plural and vice versa.
      <br />
      <br />
      (c)
      {" "}
      <b>Headings:</b>
      {" "}
      The headings and subheadings used in these Terms of
      Service are for convenience only and shall not affect the interpretation
      of the content herein.
      <br />
      <br />
      (d)
      {" "}
      <b>Gender Neutral:</b>
      {" "}
      Words importing any gender include all genders.
      <br />
      <br />
      (e)
      {" "}
      <b>Including and Includes:</b>
      {" "}
      The words "including" and "includes"
      mean "including, but not limited to."
      <br />
      <br />
      (f)
      {" "}
      <b>References to Statutes:</b>
      {" "}
      Any reference to a statute or statutory
      provision is a reference to it as it is in force for the time being,
      taking into account any amendment, extension, or re-enactment and includes
      any subordinate legislation for the time being in force made under it.
      <br />
      <br />
      (g)
      {" "}
      <b>Entire Agreement:</b>
      {" "}
      These Terms of Service, including any
      documents or policies referenced herein, constitute the entire agreement
      between TA Fintech Inc. and the User concerning the subject matter hereof
      and supersedes all prior agreements, whether written or oral.
      <br />
      <br />
      (h)
      {" "}
      <b>Amendments:</b>
      {" "}
      TA Fintech Inc. reserves the right to amend or
      modify these Terms of Service at any time, and such amendments shall be
      effective upon posting on the TradeGPT website.
      <br />
      <br />
      (i)
      {" "}
      <b>Severability:</b>
      {" "}
      If any provision of these Terms of Service is
      determined to be invalid or unenforceable, the remaining provisions shall
      continue to be valid and enforceable to the fullest extent permitted by
      law.
      <br />
      <br />
      <p className="font-bold text-[28px]">3. Acceptance of Terms</p>
      <br />
      By accessing or using TradeGPT, hereinafter referred to as "the Chatbot,"
      you acknowledge and agree to be bound by these comprehensive Terms of
      Service. Your access and use of the Chatbot are contingent upon your
      understanding, acceptance, and compliance with the terms, conditions, and
      notices outlined herein. If you do not agree with any part of these terms,
      or if you do not understand any aspect of these Terms of Service, you are
      kindly requested to refrain from using the Chatbot.
      <br />
      <br />
      As a user, you affirm that you are of legal age and have the capacity to
      enter into a legally binding agreement. If you are accessing the Chatbot
      on behalf of an entity, you further confirm that you have the authority to
      bind that entity to these Terms of Service. Your use of the Chatbot
      constitutes your acknowledgment that you have read, understood, and agree
      to abide by the terms and conditions set forth herein.
      <br />
      <br />
      <p className="font-bold text-[28px]">4. Description of Service</p>
      <br />
      The Chatbot, developed and maintained by TA Fintech Inc., is a
      cutting-edge and innovative conversational interface designed to provide
      users with an engaging and interactive experience. Through natural
      language processing and advanced algorithms, the Chatbot offers a diverse
      range of functionalities, including but not limited to providing
      conversational assistance, information retrieval, task automation,
      entertainment, education, customizable interactions, continuous
      improvement, and prioritizing privacy and data security.
      <br />
      <br />
      The primary aim of the Chatbot is to enhance user interaction with
      technology by providing a seamless and intuitive platform for
      communication. Whether you seek information, engage in discussions, or
      utilize specific features within the Chatbot, the service is crafted to
      adapt and respond dynamically to user input.
      <br />
      <br />
      In its ongoing commitment to innovation, TA Fintech Inc. may introduce new
      features, enhancements, or modifications to the Chatbot, which will be
      subject to these Terms of Service. The description of services provided
      here serves as a foundational overview, and users are encouraged to
      explore and utilize the Chatbot to experience its full range of
      capabilities.
      <br />
      <br />
      The Chatbot is not intended to replace professional advice, and users are
      advised to use their discretion in relying on information obtained through
      the Chatbot. While TA Fintech Inc. strives to provide accurate and
      up-to-date information, it does not warrant the completeness, accuracy, or
      reliability of any information provided by the Chatbot.
      <br />
      <br />
      Your use of the Chatbot also falls under the purview of our
      {" "}
      <b>
        Privacy
        Policy
      </b>
      , which details how we collect, use, and protect your information.
      By engaging with the Chatbot, you consent to the practices outlined in the
      {" "}
      <b>Privacy Policy</b>
      .
      <br />
      <br />
      <p className="font-bold text-[28px]">5. User Conduct</p>
      <br />
      In order to foster a positive, inclusive, and secure environment for all
      users, it is imperative that individuals conduct themselves responsibly
      and ethically while engaging with TradeGPT, hereinafter referred to as
      "the Chatbot." As a user, you are expected to adhere to the highest
      standards of online conduct, ensuring that your interactions contribute
      positively to the community experience.
      <br />
      <br />
      Users must engage with the Chatbot in a manner that is respectful,
      considerate, and responsible. This includes refraining from any form of
      abusive language, harassment, or disrespectful behavior towards other
      users, TA Fintech Inc., or any third parties associated with the Chatbot.
      The Chatbot is intended for lawful and constructive purposes. Users are
      explicitly prohibited from engaging in any activities that are unlawful,
      illegal, or in violation of applicable local, national, or international
      laws and regulations. This encompasses, but is not limited to, activities
      such as fraud, unauthorized access, or any other criminal or malicious
      conduct.
      <br />
      <br />
      Any behavior that disrupts, interferes with, or compromises the
      functionality of the Chatbot is strictly forbidden. Users are expected to
      utilize the Chatbot in a manner that respects its intended purpose and
      does not cause harm or inconvenience to other users or the overall
      performance of the service. Users are encouraged to familiarize themselves
      with and adhere to any community guidelines or specific rules provided by
      TA Fintech Inc. regarding user conduct within the Chatbot. Failure to
      comply with these guidelines may result in the suspension or termination
      of user access.
      <br />
      <br />
      Users play an essential role in maintaining the integrity of the Chatbot
      community. If you become aware of any violations of these user conduct
      provisions or observe behavior that is inappropriate or disruptive, it is
      encouraged to report such incidents to TA Fintech Inc.. Engaging in
      prohibited activities or violating user conduct provisions may result in
      legal consequences, including but not limited to civil or criminal
      liability. TA Fintech Inc. reserves the right to take appropriate legal
      action in response to any violations.
      <br />
      <br />
      <p className="font-bold text-[28px]">6. Beta Services</p>
      <br />
      From time to time, TA Fintech Inc. may introduce new features,
      functionalities, or components collectively referred to as "Beta
      Services." These services are provided on a trial basis and may be subject
      to additional terms and conditions. Users opting to engage with Beta
      Services acknowledge that these features are in the testing phase and may
      undergo modifications or discontinuation at TA Fintech Inc.'s discretion.
      <br />
      <br />
      TA Fintech Inc. encourages users to provide feedback on Beta Services, as
      this input plays a pivotal role in refining and enhancing the overall user
      experience. However, TA Fintech Inc. retains the right to use, modify, and
      incorporate this feedback without any obligation to the user providing
      such input.
      <br />
      <br />
      Users engaging with Beta Services should be cognizant of the inherent
      risks associated with services in the testing phase. TA Fintech Inc.
      disclaims all warranties related to Beta Services, and users utilize these
      features at their own risk.
      <br />
      <br />
      <p className="font-bold text-[28px]">7. Team and Enterprise</p>
      <br />
      TA Fintech Inc. may offer team or enterprise accounts with enhanced
      features and capabilities tailored for organizational use. Users opting
      for these accounts agree to abide by additional terms and conditions that
      may be specified separately. Team and enterprise administrators may have
      additional responsibilities, including managing user access, permissions,
      and compliance with organizational policies.
      <br />
      <br />
      Team and enterprise accounts may involve specific payment and billing
      arrangements, distinct from individual user accounts. Users are
      responsible for familiarizing themselves with the financial terms
      associated with team and enterprise subscriptions, and any payment
      obligations incurred by the organization.
      <br />
      <br />
      In cases where a team or enterprise account is terminated, access to
      associated features and services may be discontinued. TA Fintech Inc.
      reserves the right to take appropriate measures to ensure compliance with
      the terms governing team and enterprise accounts.
      <br />
      <br />
      <p className="font-bold text-[28px]">8. Intellectual Property</p>
      <br />
      Users retain ownership of any content they submit, post, or display on
      or through TradeGPT. However, by doing so, users grant TA Fintech Inc.
      a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt,
      and distribute such content for the limited purposes of
      <List sx={{ listStyleType: "lower-alpha", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                providing and improving TradeGPT;
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                promoting the services offered by TradeGPT; and
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                developing new technologies and services offered by TradeGPT.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      All rights, title, and interest in and to TradeGPT, including all associated
      intellectual property rights, are and will remain the exclusive property of
      TA Fintech Inc.. Users are prohibited from using TradeGPT in any way that
      infringes upon or violates TA Fintech Inc.'s intellectual property rights.
      <br />
      <br />
      <p className="font-bold text-[28px]">9. Confidentiality</p>
      <br />
      Users agree to maintain the confidentiality of any non-public information obtained
      or accessed through the use of TradeGPT ("Confidential Information"). This includes,
      but is not limited to, proprietary algorithms, trade secrets, and any other information
      marked as confidential by TA Fintech Inc.. Users shall not disclose, reproduce, or use
      Confidential Information for any purpose other than the intended use of TradeGPT. This
      obligation of confidentiality remains in effect even after the termination of user access
      to TradeGPT.
      <br />
      <br />
      <p className="font-bold text-[28px]">10. Images and Plugins Integration</p>
      <br />
      Users may have the option to integrate images and plugins into their interactions with
      TradeGPT. These features enhance the user experience by allowing the incorporation of visual
      elements and additional functionalities. Users are responsible for ensuring that any images
      or plugins integrated comply with these Terms of Service and applicable laws. Users are also
      responsible for ensuring that any images or plugins integrated do not infringe any
      intellectual property rights of others and that they have the right to integrate the images
      and plugins in their interactions with TradeGPT.
      <br />
      <br />
      Users retain ownership of any images or plugins they integrate into TradeGPT. However, by
      integrating such content, users grant TA Fintech Inc. a worldwide, non-exclusive, royalty-free
      license to use, display, and distribute these images and plugins for the limited purposes of
      <List sx={{ listStyleType: "lower-alpha", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                providing and improving TradeGPT;
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                promoting the services offered by TradeGPT; and
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingY: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                developing new technologies and services offered by TradeGPT.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      Users are expected to adhere to any guidelines or policies provided by
      TA Fintech Inc. regarding the acceptable use and integration of images
      and plugins. Non-compliance may result in the removal of such content, and,
      in severe cases, the suspension or termination of user access.
      <br />
      <br />
      <p className="font-bold text-[28px]">11. Documents and Images Upload Feature</p>
      <br />
      Users also have the option to upload documents/images, into their interactions
      with TradeGPT. These features enhance functionality and user experience by incorporating
      document and visual support, allowing users to convey their queries using visual or
      textual content like images and documents.
      <br />
      <br />
      <b>User Responsibilities and Content Standards</b>
      <br />
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users are responsible for ensuring that documents/images uploaded into the
                chatbot comply with these Terms of Service and applicable laws.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                By uploading or integrating such content, users confirm that they possess all necessary
                rights to upload, share, and distribute such content in compliance with applicable laws
                and these Terms of Service.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users acknowledge and agree that they are solely responsible for any content they upload
                to TradeGPT. TA Fintech Inc. does not review, monitor, or verify user-uploaded content for
                intellectual property compliance and shall not be held liable for any claims, damages, or
                legal consequences arising from the infringement of intellectual property rights caused
                by user-submitted content. Users assume full responsibility for ensuring their content
                complies with all applicable laws and regulations.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users agree not to upload or integrate any content that:
              </p>
            )}
          />
          <List sx={{ listStyleType: "circle", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    contains sensitive personal information;
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    violates any intellectual property rights of third parties;
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    is offensive; or
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    violates any applicable laws.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
      </List>
      <br />
      <b>Intellectual Property</b>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users retain ownership of any documents/images uploaded into TradeGPT.
                Further, users also own the output generated by TradeGPT. However, users
                grant TA Fintech Inc. a non-exclusive, worldwide, royalty-free license to
                use, reproduce, adapt, publish, and distribute the uploaded documents/images
                as well as the generated output for the limited purposes of:
              </p>
            )}
          />
          <List sx={{ listStyleType: "circle", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    providing and improving TradeGPT;
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    promoting the services offered by TradeGPT; and
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    developing new technologies and services offered by TradeGPT.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users are expected to adhere to any guidelines or policies provided by
                TA Fintech Inc. regarding the acceptable use of the documents/images upload
                feature. Non-compliance may result in the removal of such content and, in
                severe cases, the suspension or termination of user access.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                TA Fintech Inc. will implement reasonable security measures to protect uploaded
                content but is not liable for any misuse of these files by other users or third
                parties.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                The privacy and confidentiality of the uploaded content will be respected in accordance
                with our Privacy Policy. Users should be aware that uploading files that are publicly
                accessible or contain public information may expose them to unintended privacy risks.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users agree to indemnify, defend, and hold harmless TA Fintech Inc., its affiliates, officers,
                directors, employees, and agents from and against any claims, liabilities, damages, losses,
                costs, and expenses (including legal fees) arising out of or related to any intellectual
                property violations resulting from content uploaded or shared by the user. This includes,
                but is not limited to, claims of copyright infringement, trademark violations, or unauthorized
                use of proprietary materials.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <b>Data Usage and Storage</b>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Uploaded files may be stored by TA Fintech Inc. for a period necessary to provide
                the services to the user or to meet legal requirements. Users can manage, modify, or
                delete their uploaded content either through their user account settings, using the
                TradeGPT’s Take Down Procedure or submitting a request to
                {" "}
                <a className="underline text-[#007BFF]" href="mailto:support@tradealgo.com">
                  support@tradealgo.com
                </a>
                .
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                TA Fintech Inc. reserves the right to delete or remove content that is found to be
                in violation of these Terms of Service or that poses a security risk.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <b>Technical Requirements and Limitations</b>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Uploaded files must meet specific technical specifications, including file size
                limits and acceptable formats, which will be detailed on the TradeGPT platform.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">12. Registration</p>
      <br />
      (a)
      {" "}
      <b>Account Creation:</b>
      {" "}
      Users may be required to create an account to access certain
      features of TradeGPT. During the registration process, users agree to provide accurate,
      current, and complete information. Users are responsible for maintaining the confidentiality
      of their account credentials and ensuring that the information associated with their account
      remains accurate and up-to-date.
      <br />
      <br />
      (b)
      {" "}
      <b>Account Security:</b>
      {" "}
      Users are responsible for all activities that occur under their
      account. TA Fintech Inc. is not liable for any unauthorized access to user accounts. Users
      agree to promptly notify TA Fintech Inc. of any unauthorized use or security breach related
      to their account.
      <br />
      <br />
      (c)
      {" "}
      <b>Termination of Account:</b>
      {" "}
      TA Fintech Inc. reserves the right to suspend or terminate
      user accounts at its discretion, including but not limited to instances of violation of these
      Terms of Service or misuse of TradeGPT. Users may also terminate their accounts at any time
      by following the specified procedures outlined by TA Fintech Inc..
      <br />
      <br />
      (d)
      {" "}
      <b>Data Accuracy:</b>
      {" "}
      Users acknowledge that the accuracy and completeness of the information
      provided during registration are essential for the effective use of TradeGPT. TA Fintech Inc.
      may, at its discretion, verify user information and take appropriate measures if inaccuracies
      are identified.
      <br />
      <br />
      <p className="font-bold text-[28px]">13. License to Software</p>
      <br />
      By accepting these Terms of Service, users are granted a limited, non-exclusive, and
      non-transferable license to utilize the software associated with TradeGPT ("the Software"). This
      license is specifically for accessing and utilizing TradeGPT and its features, with the
      understanding that it is not to be sublicensed, sold, or distributed without explicit
      authorization from TA Fintech Inc..
      <br />
      <br />
      The license provides users with the right to use the Software solely for its intended
      purpose within the framework of TradeGPT. Any usage beyond the scope defined in these\
      Terms of Service is expressly prohibited. Users agree not to engage in activities such
      as reverse engineering, decompiling, or disassembling the Software.
      <br />
      <br />
      All rights, title, and interest in the Software, including any updates or
      modifications, remain the exclusive property of TA Fintech Inc.. This license does not confer
      upon users any rights to the underlying intellectual property associated with the Software,
      except as expressly stated in these Terms of Service.
      <br />
      <br />
      <p className="font-bold text-[28px]">14. License Restrictions</p>
      <br />
      In conjunction with the granted license, users are expressly restricted from engaging
      in activities that compromise the integrity, security, or functionality of the
      Software. Prohibited activities include but are not limited to modifying, duplicating,
      or distributing the Software without explicit authorization from TA Fintech Inc..
      <List sx={{ listStyleType: "lower-alpha", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users agree not to reverse engineer, decompile, or disassemble the Software or any
                of its components. Any attempt to extract the source code or gain insights into the
                proprietary aspects of the Software is strictly prohibited.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Unauthorized usage of the Software or any attempt to exceed the authorized scope
                outlined in these Terms of Service is strictly prohibited. Violation of these license
                restrictions may result in the termination of the license and legal consequences.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users shall not circumvent or attempt to circumvent any security measures implemented
                by TA Fintech Inc. to protect the Software. Any unauthorized access, tampering, or
                interference with the Software's security features is expressly forbidden.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">15. Termination</p>
      <br />
      TA Fintech Inc. reserves the right to terminate or suspend user access to
      TradeGPT at any time, with or without cause, and with or without notice. Termination
      may result from violation of these Terms of Service, misuse of TradeGPT, or any conduct
      deemed by TA Fintech Inc. to be harmful or disruptive. Users may terminate their account
      and cease using TradeGPT at any time. Upon termination, users are still bound by the
      obligations and restrictions outlined in these Terms of Service, including but not
      limited to confidentiality and intellectual property provisions.
      <br />
      <br />
      <p className="font-bold text-[28px]">16. Limited Liability Clause</p>
      <br />
      TA Fintech Inc. shall not be liable for any direct, indirect, incidental,
      special, or consequential damages arising out of or relating to these Terms
      of Service or resulting from the use or the inability to use TradeGPT’s services,
      including but not limited to damages for loss of profits, use, data, or other
      intangible damages, even if such party has been advised of the possibility of such damages.
      <br />
      <br />
      <p className="font-bold text-[28px]">17. Privacy and Data Security</p>
      <br />
      TA Fintech Inc. collects and processes personal data as outlined in its
      {" "}
      <b>Privacy Policy</b>
      .
      By using the TradeGPT’s services, you consent to data collection and processing for regulatory
      compliance, service improvement, and fraud prevention. TA Fintech Inc. does not sell or
      share user financial data for marketing purposes.
      <br />
      <br />
      <p className="font-bold text-[28px]">18. Governing Law and Dispute Resolution</p>
      <br />
      Any disputes, claims, or controversies shall first be resolved through
      {" "}
      <b>
        good-faith
        mediation
      </b>
      {" "}
      between the user and TA Fintech Inc.
      <br />
      <br />
      If mediation does not result in a resolution within
      {" "}
      <b>30 days</b>
      {" "}
      of initiation, the dispute
      shall be referred to
      {" "}
      <b>binding arbitration</b>
      {" "}
      conducted in accordance with the rules of a
      recognized arbitration institution chosen by TA Fintech Inc. The
      {" "}
      <b>
        governing law shall
        be Delaware
      </b>
      , and the arbitral decision rendered shall be final and enforceable.
      <br />
      <br />
      Users agree not to initiate any court action without first exhausting the mediation and
      arbitration process.
      <br />
      <br />
      <p className="font-bold text-[28px]">19. Changes to These Terms</p>
      <br />
      TA Fintech Inc. reserves the right to modify, alter, or update these Terms
      of Service at any time. Users will be notified of material changes through
      reasonable means. Continued use of TradeGPT after such modifications constitutes
      acceptance of the updated terms. It is the user's responsibility to review these
      Terms of Service periodically for changes. TA Fintech Inc. will make reasonable efforts
      to notify users of any material changes to these Terms of Service. Notification may be
      provided through TradeGPT, email, or other communication channels. Users are encouraged
      to stay informed about any updates to ensure compliance with the latest terms.
      <br />
      <br />
      <p className="font-bold text-[28px]">20. Contact Information</p>
      <br />
      If you have any questions, concerns, or requests regarding these Terms of Service, please
      contact us at
      {" "}

      <a className="underline text-[#007BFF]" href="mailto:support@tradealgo.com">
        support@tradealgo.com
      </a>
      .
      <br />
      <br />
      We are committed to and appreciate the opportunity to address your concerns.
    </TermsAndPoliciesLayout>
  );
}
export default withScrollToTop(TermsOfService);
