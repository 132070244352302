import { useState, useRef, useEffect, useCallback } from "react";
import Watchlist from "../Watchlist";
import TodaySummarySection from "../NewsSummarySection/TodaySummaryList";
import { throttle } from "lodash";
import { PANELS } from "../Watchlist/constants";
import { useSelector } from "react-redux";
import classNames from "classnames";

type RightSidebarProps = {
  openWatchlist: boolean;
  handlePrompt: (msg: string) => void;
};

const COLLAPSED_HEIGHT = 0; // height when panel is collapsed (only heading shown)
const HALF_HEIGHT = 0.5; // height of panel when it takes up half of available space
const FULL_HEIGHT = 1; // height of panel when it is expanded
const OFFSET_HEIGHT = 66;
const ALLOWED_HEIGHTS = [COLLAPSED_HEIGHT, HALF_HEIGHT, FULL_HEIGHT];

const RightSidebar = ({ openWatchlist, handlePrompt }: RightSidebarProps) => {
  const [showFullWatchlist, setShowFullWatchlist] = useState(false);
  const {
    selectedTickerProps,
  } = useSelector((state: { newsSummary }) => state.newsSummary);

  const [watchlistHeight, setWatchlistHeight] = useState(window.innerHeight * 0.5);
  const [newsSummaryHeight, setNewsSummaryHeight] = useState(window.innerHeight * 0.5);

  const [isDragging, setIsDragging] = useState(false);
  const dividerRef = useRef<HTMLDivElement | null>(null);

  const [startY, setStartY] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartY(e.clientY);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      const deltaY = startY - e.clientY;
      const newHeight = newsSummaryHeight + deltaY;

      const minHeight = window.innerHeight * COLLAPSED_HEIGHT;
      const maxHeight = window.innerHeight * FULL_HEIGHT;

      if (newHeight < minHeight) {
        setNewsSummaryHeight(minHeight);
      }
      else if (newHeight > maxHeight) {
        setNewsSummaryHeight(maxHeight);
      }
      else {
        setNewsSummaryHeight(newHeight);
      }
      setWatchlistHeight(window.innerHeight - newHeight);
      setStartY(e.clientY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    // Snap to the nearest allowed height
    const currentPercent = newsSummaryHeight / window.innerHeight;
    const nearestPercent = ALLOWED_HEIGHTS.reduce((prev, curr) =>
      Math.abs(curr - currentPercent) < Math.abs(prev - currentPercent) ? curr : prev,
    );

    const offsetHeight = (nearestPercent - HALF_HEIGHT) * 2 * OFFSET_HEIGHT;
    const snappedHeight = window.innerHeight * nearestPercent - offsetHeight;

    setNewsSummaryHeight(snappedHeight);
    setWatchlistHeight(window.innerHeight - snappedHeight);
  };

  const throttledMouseMove = useCallback(
    throttle(e => handleMouseMove(e), 100),
    [handleMouseMove],
  );
  useEffect(() => {
    const handleResize = () => {
      // Recalculate heights when the window is resized
      const windowHeight = window.innerHeight;
      setNewsSummaryHeight(prevHeight => windowHeight * (prevHeight / window.innerHeight));
      setWatchlistHeight(prevHeight => windowHeight * (prevHeight / window.innerHeight));
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousemove", throttledMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousemove", throttledMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      throttledMouseMove.cancel();
    };
  }, [isDragging, newsSummaryHeight, watchlistHeight]);

  const toggleHeights = useCallback((section: (PANELS.TODAYSUMMARY | PANELS.WATCHLIST)) => {
    const windowHeight = window.innerHeight;
    const epsilon = 0.0001;
    if (section === PANELS.WATCHLIST) {
      if (Math.abs((windowHeight * FULL_HEIGHT) - watchlistHeight - OFFSET_HEIGHT) < epsilon) {
        setWatchlistHeight(windowHeight * HALF_HEIGHT);
        setNewsSummaryHeight(windowHeight * HALF_HEIGHT);
      }
      else {
        setWatchlistHeight(windowHeight * FULL_HEIGHT - OFFSET_HEIGHT);
        setNewsSummaryHeight(windowHeight * COLLAPSED_HEIGHT + OFFSET_HEIGHT);
      }
    }
    else if (section === PANELS.TODAYSUMMARY) {
      if (newsSummaryHeight === windowHeight * FULL_HEIGHT - OFFSET_HEIGHT) {
        setNewsSummaryHeight(windowHeight * HALF_HEIGHT);
        setWatchlistHeight(windowHeight * HALF_HEIGHT);
      }
      else {
        setNewsSummaryHeight(windowHeight * FULL_HEIGHT - OFFSET_HEIGHT);
        setWatchlistHeight(windowHeight * COLLAPSED_HEIGHT + OFFSET_HEIGHT);
      }
    }
  }, [watchlistHeight, newsSummaryHeight]);

  const isWatchlistExpanded = watchlistHeight > window.innerHeight * HALF_HEIGHT;
  const isNewsListExpanded = newsSummaryHeight > window.innerHeight * HALF_HEIGHT;

  const resizeAnimation = isDragging ? "" : "transition-all duration-300 ease-in-out";

  return (
    <div
      className={classNames(
        "text-mui-black-87 dark:text-white z-10 w-full md:w-[360px] shadow-custom flex flex-col",
        {
          "dark:bg-background/paper-elevation-4": selectedTickerProps.ticker || showFullWatchlist,
          "bg-mui-black-04 dark:bg-background/paper-elevation-1 p-[12px]": !selectedTickerProps.ticker && !showFullWatchlist,
          "hidden": !openWatchlist,
        },
      )}
    >
      <>
        <Watchlist isOpen={openWatchlist} height={watchlistHeight} setShowFullWatchlist={setShowFullWatchlist} toggleHeights={toggleHeights} isWatchlistExpanded={isWatchlistExpanded} resizeAnimation={resizeAnimation} handlePrompt={handlePrompt} />
        {/* show only watchlist when watchlist is in search or list management mode */}
        {!showFullWatchlist
        && (
          <>
            <div
              className="relative flex justify-center items-center cursor-row-resize group"
              ref={dividerRef}
              onMouseDown={handleMouseDown}
            >
              <div
                className={classNames(
                  "bg-mui-black-12 dark:bg-mui-white-12 rounded-lg w-[90px] my-1 h-1 transition-opacity duration-200",
                  {
                    "opacity-100 bg-mui-black-56 dark:bg-mui-white-56": isDragging,
                    "opacity-0 group-hover:opacity-100": !isDragging,
                  },
                )}
              >
              </div>
            </div>
            {!selectedTickerProps.ticker && <TodaySummarySection height={newsSummaryHeight} toggleHeights={toggleHeights} isNewsListExpanded={isNewsListExpanded} resizeAnimation={resizeAnimation} />}
          </>
        )}
      </>
    </div>
  );
};

export default RightSidebar;
