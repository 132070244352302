import { RootState } from "../../assets/interfaces/interfaces";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { fetchSharedSession } from "../../redux/slices/sharedSessionSlice";
import { shareTransform } from "../../helpers";
import ChatMessage from "../ChatMessage";
import useEnhancedNavigate from "../Session/helpers";
import ImageModal from "../ImageModal";

const SharedChat = () => {
  const [messages, setMessages] = useState([]);
  const user = useSelector((state: RootState) => state.auth.currentUser);
  const navigate = useEnhancedNavigate();
  const dispatch = useDispatch<AppDispatch>(); // Use the typed dispatch
  const { session_id, title } = useParams();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const {
    pastMessages,
    // status
  } = useSelector((state: RootState) => state.sharedSession);
  useEffect(() => {
    dispatch(fetchSharedSession({ sessionId: session_id }));
  }, [session_id, dispatch]);

  useEffect(() => {
    if (pastMessages.length) {
      const formattedPastChat = shareTransform(pastMessages);
      setMessages(formattedPastChat);
    }
  }, [pastMessages, user]);

  // handling edited title - if none, use default title from db
  const decodedTitle = useMemo(() => {
    if (title) {
      return atob(title);
    }
    return "";
  }, [title]);

  const defaultTitle = useMemo(() => {
    if (!!messages[0] && messages[0]?.title) {
      // Remove quotes from the title
      return messages[0].title.replace(/^"|"$/g, "");
    }
    return "";
  }, [messages]);

  const sessionTitle = decodedTitle || defaultTitle;

  const session_date = useMemo(() => {
    if (!!messages[0] && messages[0]?.date) {
      // Remove quotes from the title
      return messages[0].date;
    }
    return "";
  }, [messages]);

  function onImageModalClose() {
    setIsImageModalOpen(false);
    setImageSrc("");
  }

  function handleImageClick(imageSrc) {
    setImageSrc(imageSrc);
    setIsImageModalOpen(true);
  }
  if (!pastMessages.length) return <></>;
  return (
    <div className="bg-white dark:bg-background/paper-elevation-1 min-h-[100vh] relative z-0 flex h-full w-full overflow-hidden">
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <div
            role="presentation"
            className="chatmessagescrollbar flex h-full flex-col"
          >
            <div className="flex-1 overflow-hidden">
              <div className="h-full overflow-auto scroll-container">
                <div className="flex flex-col pb-4 text-sm pt-2">
                  <div className="mx-auto w-full p-4 md:max-w-2xl md:px-0 lg:max-w-[38rem] xl:max-w-3xl">
                    <div className="border-b border-gray-100 pb-4 pt-3 sm:mb-2 sm:pb-6 sm:pt-8">
                      <h1 className="text-3xl font-semibold leading-tight text-gray-700 dark:text-gray-100 sm:text-4xl">
                        {sessionTitle}
                      </h1>
                      <div className="pt-3 text-base text-gray-400 sm:pt-4">
                        {session_date}
                      </div>
                    </div>
                  </div>
                  <div
                    className="w-full text-token-text-primary"
                    data-testid="conversation-turn-2"
                  >
                    <div className="px-4 py-2 justify-center text-base md:gap-6 m-auto">
                      <div className="flex flex-col text-base mx-auto gap-3 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group">
                        {messages.map((message, i) => (
                          <ChatMessage
                            key={i}
                            message={message}
                            isPreview={true}
                            onImageClick={handleImageClick}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pb-4 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
              <div className="relative  flex w-full flex-1 items-center justify-center gap-2 pt-3 empty:hidden">
                <button
                  onClick={() => navigate("/chat")}
                  className="cursor-pointer flex bg-[#007BFF] items-center gap-2 rounded-xl px-5 py-3.5 font-medium text-white transition hover:opacity-70"
                >
                  <div>
                    Get started with
                    <span className="font-bold"> TradeGPT</span>
                  </div>
                </button>
              </div>
              {/* <div className="relative px-2 py-2 text-center text-xs text-gray-600 dark:text-gray-300 md:px-[60px]">
                                <div className="flex justify-center gap-3 text-gray-500">
                                    <button>Report content</button>
                                    <span>|</span><a  rel="noreferrer">Terms of use</a>
                                    <span>|</span><a  rel="noreferrer">Privacy policy</a>
                                    <span>|</span><button>Manage cookies</button>
                                </div>
                            </div> */}
            </div>
          </div>
        </main>
      </div>
      <ImageModal open={isImageModalOpen} onClose={onImageModalClose} imageSrc={imageSrc} />
    </div>
  );
};

export default SharedChat;
