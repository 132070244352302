import useEnhancedNavigate from "../Session/helpers";
import TermsAndPoliciesHeader from "./components/TermsAndPoliciesHeader";
import TermsAndPoliciesNavigation from "./components/TermsAndPoliciesNavigation";

interface TermsAndPoliciesOptionItem {
  title: string;
  description: string;
  url: string;
}

const legalActions: TermsAndPoliciesOptionItem[] = [
  {
    title: "Terms of Service",
    description:
      "These Terms of Service govern your use of our cutting-edge chatbot, TradeGPT. Please take a moment to carefully read and understand the following terms before engaging with our service.",
    url: "/policies/service-terms",
  },
  {
    title: "Privacy Policy",
    description:
      "This document serves as a legally binding agreement between you, the user, and TA Fintech Inc. This Privacy Policy outlines the way we collect, utilize, disclose, and safeguard your information when you engage with our service.",
    url: "/policies/privacy-policy",
  },
  {
    title: "Legal Disclaimer",
    description: `This legal disclaimer governs the utilization of TradeGPT.  All content, tools, and information provided through TradeAlgo’s platform are strictly for informational and educational purposes.`,
    url: "/policies/legal-disclaimer",
  },
  {
    title: "Take Down Procedure",
    description: `This document establishes the process for handling requests related to the removal of your Content for TradeGPT.`,
    url: "/policies/take-down-policy",
  },
];

export default function TermsAndPolicies() {
  const navigate = useEnhancedNavigate();

  const onOptionClicked = (optionUrl: string) => {
    navigate(optionUrl);
  };

  return (
    <div className="w-screen h-screen bg-white">
      <div className="flex flex-col overflow-y-auto overflow-x-auto w-full h-full">
        {/* Nav */}
        <TermsAndPoliciesNavigation />

        <div className="px-6 pt-10 pb-[55px] space-y-10">
          {/* Header */}
          <TermsAndPoliciesHeader title="Terms & Policies" />
          {/* Content */}

          <div className="space-y-10">
            <h1 className="text-[48px] text-[#525252] font-['Inter'] font-normal leading-[58px]">
              Legal
            </h1>

            <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
              {legalActions.map(x => (
                <div
                  key={x.title}
                  className="flex flex-col h-[410px] w-full min-w-64 cursor-pointer md:w-[328px] border border-colorsseparatordark p-6"
                  onClick={() => onOptionClicked(x.url)}
                >
                  <div className="flex-1">
                    <p className="text-[28px] text-[#1C1C1E] font-['Inter'] font-bold">
                      {x.title}
                    </p>
                  </div>
                  <p className="text-lg text-[#1C1C1E] font-['Inter'] font-normal">
                    {x.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
