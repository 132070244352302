import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button } from "@mui/material";
import { ReactComponent as RegenerateIcon } from "../../assets/icons/RegenerateIcon.svg";

type ErrorMessage = {
  handleRegenerate: () => void;
  allExpired: boolean;
};
export default function ErrorMessage({ handleRegenerate, allExpired }: ErrorMessage) {
  return (
    <div className="rounded-[4px] py-2 px-4 bg-components-alert-warning-background dark:bg-components-alert-warning-background-dark flex justify-between">
      <div className="flex gap-x-2 items-center">
        <WarningRoundedIcon className="!fill-warning-main dark:!fill-warning-main-dark" />
        <div className="body2">{allExpired ? "These news summaries have expired." : "Some news summaries have expired."}</div>
      </div>
      <Button
        variant="text"
        onClick={handleRegenerate}
        className="!text-transform-none !text-warning-main dark:!text-warning-main-dark flex items-center gap-x-2"
      >
        <p className="pe-2">Regenerate</p>
        <RegenerateIcon />
      </Button>
    </div>
  );
}
