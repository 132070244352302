import bezinga from "../assets/logos/bezinga.svg";
import finnhub from "../assets/logos/finnhub.svg";
import fool from "../assets/logos/fool.svg";
import globenewswire from "../assets/logos/globenewswire.svg";
import seekingAlpha from "../assets/logos/seekingAlpha.svg";
import investing from "../assets/logos/investing.svg";
import polygon from "../assets/logos/polygon.svg";
import wallSt from "../assets/logos/wallSt.svg";
import onlineInvestor from "../assets/logos/onlineInvestor.svg";
import etfChannel from "../assets/logos/etfChannel.svg";
import yahooFin from "../assets/logos/yahooFin.svg";
import marketWatch from "../assets/logos/marketWatch.svg";
import investorPlace from "../assets/logos/investorPlace.svg";
import guru from "../assets/logos/guru.svg";
import theFly from "../assets/logos/theFly.svg";
import talkMarkets from "../assets/logos/talkMarkets.svg";
import fintel from "../assets/logos/fintel.svg";
import dowJones from "../assets/logos/dowJones.svg";
import tipRanks from "../assets/logos/tipRanks.svg";
import accessWire from "../assets/logos/accessWire.svg";
import investorsbd from "../assets/logos/investorsbd.svg";
import wallStreetJournal from "../assets/logos/wallStreetJournal.svg";

export const NEWS_LOGOS: Record<string, string> = {
  "benzinga.com": bezinga,
  "finnhub.io": finnhub,
  "static2.finnhub.io": finnhub,
  "fool.com": fool,
  "globenewswire.com": globenewswire,
  "seekingalpha.com": seekingAlpha,
  "investing.com": investing,
  "polygon.com": polygon,
  "247wallst.com": wallSt,
  "theonlineinvestor.com": onlineInvestor,
  "etfchannel.com": etfChannel,
  "finance.yahoo.com": yahooFin,
  "marketwatch.com": marketWatch,
  "investorplace.com": investorPlace,
  "guru.com": guru,
  "thefly.com": theFly,
  "talkmarkets.com": talkMarkets,
  "fintel.io": fintel,
  "dowjones.com": dowJones,
  "tipranks.com": tipRanks,
  "accessnewswire.com": accessWire,
  "investors.com": investorsbd,
  "wsj.com": wallStreetJournal,
};

export const NEWS_SOURCES: Record<string, string> = {
  "benzinga.com": "Benzinga",
  "finnhub.io": "Finnhub",
  "static2.finnhub.io": "Finnhub",
  "fool.com": "The Motley Fool",
  "globenewswire.com": "Globe News Wire",
  "seekingalpha.com": "Seeking Alpha",
  "investing.com": "Investing",
  "investors.com": "Investor's Business Daily",
  "marketwatch.com": "Market Watch",
  "wsj.com": "Wall Street Journal",
  "finance.yahoo.com": "Yahoo Finance",
};
