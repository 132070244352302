import { useEffect, useMemo, useState } from "react";
import TickerCard from "./Ticker";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import { AppDispatch } from "../../redux/store";
import { TickerInfo } from "../../assets/interfaces/interfaces";
import { MODE } from "./constants";
import SummaryCard from "../NewsSummarySection/SummaryCard";
import { NewsSummaryStatic } from "../Icons/NewsSummaryAnimated";
import { fetchTickersData } from "../../redux/slices/watchlistSlice";
import { useFetchAllNewsForTicker } from "../NewsSummarySection/hooks";
import { updateSelectedTickerProps } from "../../redux/slices/newsSummarySlice";
import { isWithinLastDay } from "../../helpers";
import classNames from "classnames";

type TickerViewProps = {
  handlePrompt: (msg: string) => void;
};
const ExpandedTickerView = ({ handlePrompt }: TickerViewProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    selectedTickerProps,
  } = useSelector((state: { newsSummary }) => state.newsSummary);
  const {
    watchlistTickersData,
    searchResultsTickersData,
  } = useSelector((state: { watchlist }) => state.watchlist);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);

  const [tickerData, setTickerData] = useState<TickerInfo>();
  const { data: allNews, isLoading } = useFetchAllNewsForTicker(selectedTickerProps.ticker);
  const latestNews = useMemo(() => {
    if (!allNews || allNews.length === 0) return null;
    return isWithinLastDay(allNews[0]?.create_timestamp) ? allNews[0] : null;
  }, [allNews]);

  const pastNews = useMemo(() => {
    if (!allNews) return [];
    return latestNews ? allNews.slice(1) : allNews;
  }, [allNews, latestNews]);

  useEffect(() => {
    if (selectedTickerProps.ticker) {
      const existingData
        = watchlistTickersData[selectedTickerProps.ticker] || searchResultsTickersData[selectedTickerProps.ticker];

      if (existingData) {
        setTickerData(existingData);
      }
      else {
        dispatch(fetchTickersData({ tickersToFetch: [selectedTickerProps.ticker] }));
      }
    }
  }, [selectedTickerProps.ticker, watchlistTickersData]);

  return (
    <div className="flex flex-col h-full p-4 overflow-y-auto watchlist-scroller box-content">
      <section>
        <IconButton
          className="!my-2 !justify-start"
          onClick={() => {
            dispatch(updateSelectedTickerProps(null));
          }}
        >
          <ArrowBackRoundedIcon className="dark:fill-mui-white-56" />
        </IconButton>
      </section>

      <div className=" bg-white dark:bg-inherit">
        <TickerCard
          ticker={{ ticker: selectedTickerProps.ticker }}
          mode={MODE.NEWS}
          tickerData={tickerData}
          handlePrompt={handlePrompt}
        />
      </div>

      <section className="flex-1 flex flex-col">
        {isLoading && (
          <div className="h-full flex items-center justify-center">
            <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16}></CircularProgress>
          </div>
        )}
        {!isLoading && (
          <>
            <section className="latestSummary">
              {latestNews && (
                <SummaryCard summaryItem={latestNews} />
              )}
            </section>
            <section className={classNames("pastSummary flex flex-col flex-grow", { "pt-6": latestNews })}>
              <div className="pb-6 flex items-center gap-x-2">
                <Divider className="flex-grow" />
                {allNews?.length > 0
                  ? (
                      <NewsSummaryStatic
                        text="RECENT NEWS SUMMARIES"
                        width={230}
                        height={32}
                        fontSize={12}
                        letterSpacing={1}
                        startColor={isDarkMode ? "rgba(128, 186, 248, 1)" : "rgba(29, 173, 255, 1)"}
                        endColor={isDarkMode ? "rgba(241, 165, 136, 1)" : "rgba(255, 112, 67, 1)"}
                      />
                    )
                  : (
                      <NewsSummaryStatic
                        text="RECENT NEWS SUMMARIES"
                        width={230}
                        height={32}
                        fontSize={12}
                        letterSpacing={1}
                        startColor={isDarkMode ? "rgba(255, 255, 255, 0.38)" : "rgba(0, 0, 0, 0.38)"}
                        endColor={isDarkMode ? "rgba(255, 255, 255, 0.38)" : "rgba(0, 0, 0, 0.38)"}
                      />
                    )}
                <Divider className="flex-grow" />
              </div>
              {!allNews?.length
              && (
                <div className="flex-1 flex items-center text-center">
                  <p className="body2 text-mui-black-60 dark:text-mui-white-70">News summaries are not available due to insufficient key events</p>
                </div>
              )}

              {pastNews?.length
                ? (
                    <div className="flex flex-col gap-y-4 overflow-y-auto">
                      {pastNews.map((pastNewsItem, index) => (
                        <SummaryCard
                          key={pastNewsItem.agg_id}
                          isLatest={latestNews === null && index === 0} // If no today's news, expand the most recent news
                          summaryItem={pastNewsItem}
                        />
                      ))}
                    </div>
                  )
                : (latestNews && (
                    <div className="flex-1 flex items-center text-center h-[280px]">
                      <p className="body2 text-mui-black-60 dark:text-mui-white-70">Past day summaries are not available due to insufficient key events.</p>
                    </div>
                  )
                  )}
            </section>
          </>
        )}
      </section>
    </div>
  );
};

export default ExpandedTickerView;
