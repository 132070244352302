import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LLMSummaryNews } from "../../assets/interfaces/interfaces";

type NewsSummaryProps = {
  selectedTickerProps: {
    ticker: string | null;
    isExpandKeyEvents: boolean ;
    latestSummaryId: number | null;
  };
  latestSummaryFromLLM: {
    [session_id: string]: {
      chat_id: string;
      news_summary: LLMSummaryNews[];
    };
  };
};

const initialState: NewsSummaryProps = {
  selectedTickerProps: {
    ticker: null,
    isExpandKeyEvents: false,
    latestSummaryId: null,
  },
  latestSummaryFromLLM: {},
};

export const newsSummarySlice = createSlice({
  name: "newsSummary",
  initialState,
  reducers: {
    updateSelectedTickerProps(
      state,
      action: PayloadAction<{
        ticker?: string | null;
        isExpandKeyEvents?: boolean;
        latestSummaryId?: number | null;
      } | null>,
    ) {
      if (action.payload === null) {
        state.selectedTickerProps = {
          ticker: null,
          isExpandKeyEvents: false,
          latestSummaryId: null,
        };
      }
      else {
        const { ticker, isExpandKeyEvents, latestSummaryId } = action.payload;
        state.selectedTickerProps.ticker = ticker ?? state.selectedTickerProps.ticker;
        state.selectedTickerProps.isExpandKeyEvents = isExpandKeyEvents ?? state.selectedTickerProps.isExpandKeyEvents;
        state.selectedTickerProps.latestSummaryId = latestSummaryId ?? state.selectedTickerProps.latestSummaryId;
      }
    },
    updateLatestSummaryFromLLM(
      state,
      action: PayloadAction<{ [session_id: string]: { chat_id: string; news_summary: LLMSummaryNews[] } | null }>,
    ) {
      const sessionId = Object.keys(action.payload)[0];
      const newEntry = action.payload[sessionId];

      if (newEntry) {
        const limitedNewsSummary = newEntry.news_summary.slice(0, 6); // max 6 summary cards to be displayed in chat
        state.latestSummaryFromLLM[sessionId] = {
          chat_id: newEntry.chat_id,
          news_summary: limitedNewsSummary,
        };
      }
      else {
        delete state.latestSummaryFromLLM[sessionId];
      }
    },
  },
});
export const {
  updateSelectedTickerProps,
  updateLatestSummaryFromLLM,
} = newsSummarySlice.actions;

export default newsSummarySlice.reducer;
