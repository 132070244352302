import React from "react";
import { numberFormatter, currencyFormatter } from "../../helpers";
import { usePrice } from "./hooks";
import { Chip, Skeleton } from "@mui/material";
import classNames from "classnames";

interface PriceProps {
  ticker: string;
  prevClose: number | null;
  lastPrice: number | null;
  showValueDiff?: boolean;
}

const Price: React.FC<PriceProps> = ({ ticker, prevClose, lastPrice, showValueDiff }) => {
  const { currentPrice, priceChange, priceChangePercentage, priceChangeClass, isFetchPrevCloseLoading } = usePrice(ticker, prevClose, lastPrice);
  let priceToDisplay = "N/A";

  if (currentPrice > 0) {
    priceToDisplay = currencyFormatter(currentPrice);
  }

  const priceDiffValue = priceChange > 0 ? `+${numberFormatter(priceChange)}` : numberFormatter(priceChange);
  return (
    <div className={`text-right tabular-nums flex flex-col justify-end space-x-2 ${showValueDiff && "gap-y-2"}`}>
      {currentPrice === undefined
        ? (
            <Skeleton
              variant="text"
              animation="wave"
              width={80}
              height={30}
              className="self-end dark:bg-white dark:opacity-12"
            />
          )
        : <div className="text-right">{priceToDisplay}</div>}
      <div className={`body2 text-right tabular-nums ${priceChangeClass} self-end flex items-center gap-x-2`}>
        {isFetchPrevCloseLoading
          ? (
              <Skeleton variant="text" animation="wave" width={30} height={20} className="self-end dark:bg-white dark:opacity-12" />
            )
          : (
              priceChangePercentage !== null
                ? `${priceChangePercentage > 0 ? "+" : ""}${priceChangePercentage.toFixed(2)}%`
                : "—"
            )}
        {showValueDiff && (
          <Chip
            label={priceDiffValue}
            size="small"
            className={classNames("pt-2", {
              "!bg-success-accent !text-success-dark": priceChange >= 0,
              "!bg-error-accent !text-error-dark": priceChange < 0,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default Price;
