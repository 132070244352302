import { Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import taLogoD from "../assets/images/finchatGPT/taLogoD.png";
import taLogoL from "../assets/images/finchatGPT/taLogoL.png";
import {
  ChatWindowProps,
  Message,
  RootState,
} from "../assets/interfaces/interfaces";
import { useSelector } from "react-redux";
import FeedbackModal from "./FeedbackModal";
import ChatMessage from "./ChatMessage";
import SharePreviewModal from "./Share/SharePreviewModal";
import { useParams } from "react-router-dom";
import ImageModal from "./ImageModal";

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard");
  }
  catch (err) {
    console.error("Failed to copy: ", err);
  }
};

// Component to display chat messages
const ChatWindow: React.FC<ChatWindowProps> = ({
  sessionId,
  messages,
  setOpen,
  isUserSignedIn,
  shareModal,
  setShareModal,
  handleSendMessage,
}) => {
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages?.[sessionId],
  );
  const { status: pastChatStatus } = useSelector(
    (state: RootState) => state.session,
  );
  // Feedback Modal States
  const { session_id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [actionMessage, setActionMessage] = useState<Partial<Message> | null>();
  const handleCopy = (text: string) => {
    copyToClipboard(text);
  };

  const handleLike = (message: Partial<Message>) => {
    setActionMessage(message);
    setIsLiked(true);
    setModalOpen(true);
  };

  const handleDislike = (message: Partial<Message>) => {
    setActionMessage(message);
    setIsLiked(false);
    setModalOpen(true);
  };

  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const loading = finchatMessage?.outputLoading;
  const authLoading = useSelector((state: { auth }) => state.auth.loading);

  const scrollIntoView = useCallback(() => {
    if (endOfMessagesRef.current) {
      queueMicrotask(() => {
        endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  }, []);
  useEffect(() => {
    scrollIntoView();
  }, [messages]);

  function onFeedbackModalClose() {
    setActionMessage(null);
    setModalOpen(false);
  }

  function onImageModalClose() {
    setIsImageModalOpen(false);
    setImageSrc("");
  }

  function handleImageClick(imageSrc) {
    setImageSrc(imageSrc);
    setIsImageModalOpen(true);
  }

  return (
    <>
      <SharePreviewModal session_id={session_id} open={shareModal} handleClose={() => setShareModal(false)} />
      <FeedbackModal isLiked={isLiked} open={modalOpen} onClose={onFeedbackModalClose} message={actionMessage} />
      <ImageModal open={isImageModalOpen} onClose={onImageModalClose} imageSrc={imageSrc} />
      {pastChatStatus !== "loading" && !authLoading && (
        <>
          {!messages?.length
            ? (
                <div className="flex h-full flex-col items-center justify-center">
                  <div className="relative">
                    <div className="mb-3 h-12 w-12">
                      <div className="relative flex h-full items-center justify-center rounded-full bg-white border border-token-border-medium">
                        <img
                          src={isDarkMode ? taLogoD : taLogoL}
                          alt="Logo"
                          className="portfolio-logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-2xl font-medium text-token-text-primary">
                    How can I help you today?
                  </div>
                  {!isUserSignedIn && (
                    <Button
                      classes={{ root: "connect-portfolio-button" }}
                      onClick={() => setOpen(true)}
                    >
                      Log In
                    </Button>
                  )}
                </div>
              )
            : (
                <>
                  {messages.map((message, index) => (
                    /* Messages */
                    <ChatMessage
                      key={`${message.chat_id}_${index}`}
                      message={message}
                      onCopy={handleCopy}
                      onImageClick={handleImageClick}
                      onLike={() => { handleLike(message); }}
                      onDislike={() => { handleDislike(message); }}
                      showActions={index + 1 === messages.length && message.isBot}
                      handleSendMessage={handleSendMessage}
                    />
                  ))}
                  {loading && !finchatMessage?.output.length && (
                  /* Initial Loading Animation */
                    <ChatMessage message={finchatMessage?.output} loading={true} onScrollRequest={scrollIntoView} />
                  )}
                  {/* show this button only when the latest messages are not visible */}
                  {/* <button className="cursor-pointer absolute z-10 rounded-full bg-clip-padding border text-token-text-secondary dark:border-white/10 dark:bg-white/10 right-1/2 border-black/10 bg-token-surface-primary bottom-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="m-1 text-black dark:text-white">
                  <path d="M17 13L12 18L7 13M12 6L12 17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </button> */}

                  <div ref={endOfMessagesRef} />
                </>
              )}
        </>
      )}
    </>
  );
};

export default ChatWindow;
