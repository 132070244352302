import React from "react";
import { Modal, Button, Typography, IconButton } from "@mui/material";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import useEnhancedNavigate from "./Session/helpers";
import { ChangeLog } from "../assets/interfaces/interfaces";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

interface ChangeLogModalProps {
  open: boolean;
  handleClose: () => void;
  log: ChangeLog;
}

const ChangeLogModal: React.FC<ChangeLogModalProps> = ({ open, handleClose, log }) => {
  const navigate = useEnhancedNavigate();
  const navigateToChangeLogs = () => {
    navigate("/release-notes");
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className="flex items-center justify-center sm:p-0">
      <div className="p-6 shadow w-modal-mobile sm:w-modal-sm md:w-modal-md rounded-lg bg-white dark:bg-background/paper-elevation-16 text-mui-black-87 dark:text-white outline-none">
        <div className="flex items-center justify-between pb-2 rounded-t-lg">
          <Typography variant="h6">
            {log.title}
          </Typography>
          <IconButton
            size="small"
            onClick={handleClose}
            className="cross-icon"
          >
            <CrossIcon className="fill-current m-1" />
          </IconButton>
        </div>
        <div className="pb-6 text-sm font-medium text-mui-black-60 dark:text-mui-white-70">
          <Typography variant="subtitle2">
            Release Note for v
            {log.version}
            {" "}
            on
            {" "}
            {moment.utc(log.date).format("MMM D, YYYY")}
          </Typography>
        </div>
        <div className="max-h-36 overflow-hidden line-clamp-6">
          <ReactMarkdown className="markdown-body change-log-markdown body2">
            {log.description}
          </ReactMarkdown>
        </div>
        <div className="pt-6 flex justify-center">
          <Button
            variant="outlined"
            className="button-outlined"
            onClick={navigateToChangeLogs}
            size="large"
          >
            Read more
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeLogModal;
