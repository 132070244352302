import { IconChevronLeftLightThin } from "../../Plans/icons/IconChevronLeftLightThin";
import { IconChevronRightLightThin } from "../../Plans/icons/IconChevronRightLightThin";
import useEnhancedNavigate from "../../Session/helpers";

export default function TermsAndPoliciesNavigation({
  type,
}: {
  type?: "service" | "privacy" | "disclaimer" | "take_down";
}) {
  const navigate = useEnhancedNavigate();

  const currentPageLabel = () => {
    if (!type) {
      return;
    }

    switch (type) {
      case "service":
        return "Terms of Service";
      case "privacy":
        return "Privacy Policy";
      case "disclaimer":
        return "Legal Disclaimer";
      case "take_down":
        return "Take Down Procedure";
    }
  };

  const onHeaderPress = () => {
    if (!type) {
      navigate("/");
      return;
    }

    navigate("/policies");
  };

  if (!type) {
    return (
      <div
        onClick={onHeaderPress}
        className="cursor-pointer flex items-center gap-1 p-4 md:p-10"
      >
        <IconChevronLeftLightThin className="!relative !w-[24px] !h-[24px] fill-[#1C1C1E]" />
        <div className="font-['Inter'] font-normal text-base text-[#1C1C1E] leading-[22px]">
          Back to TradeGPT
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-3 p-4 md:p-10">
      <div className="cursor-pointer" onClick={onHeaderPress}>
        <p className="font-['Inter'] font-normal text-base text-[#1C1C1E] leading-[22px]">
          Terms & Policies
        </p>
      </div>
      <IconChevronRightLightThin className="!relative !w-[20px] !h-[20px]" />
      <p className="font-['Inter'] font-normal text-base text-[#A3A3A3] leading-[22px]">
        {currentPageLabel()}
      </p>
    </div>
  );
}
