import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ChatMessage, FetchSessionArg, PastChatState } from "../../assets/interfaces/interfaces";
import { updateLatestSummaryFromLLM } from "./newsSummarySlice";

const initialState: PastChatState = {
  pastMessages: [],
  status: "idle",
  error: null,
};

export const fetchPastSession = createAsyncThunk(
  "chat/fetchPastSession",
  async (arg: FetchSessionArg, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SPL_URL}/tradegpt-session/${arg.sessionId}`, { withCredentials: true });
      const latestMessage = data
        .filter(message => message.news_summaries && message.news_summaries.length > 0)
        .sort((a, b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf())[0];

      if (latestMessage) {
        const { session_id, message_id, news_summaries } = latestMessage;

        dispatch(
          updateLatestSummaryFromLLM({
            [session_id]: { chat_id: message_id, news_summary: news_summaries },
          }),
        );
      }
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPastSession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPastSession.fulfilled, (state, action: PayloadAction<ChatMessage[]>) => {
        state.status = "succeeded";
        state.pastMessages = action.payload;
      })
      .addCase(fetchPastSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch messages";
      });
  },
});

// Export the reducer from the slice
export default sessionSlice.reducer;
