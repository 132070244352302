import { FC } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { twMerge } from "tailwind-merge";

interface TextBannerProps {
  icon?: JSX.Element;
  className?: string;
  hasAction?: boolean;
  hasClose?: boolean;
  actionCallback?: React.MouseEventHandler;
  closeCallback?: React.MouseEventHandler;
  title: string;
  text: string;
  buttonText?: string;
}
const TextBanner: FC<TextBannerProps> = ({
  icon,
  title,
  text,
  buttonText,
  className,
  hasAction = true,
  hasClose = true,
  actionCallback,
  closeCallback,
}) => {
  const rootClassName = twMerge(
    "w-full py-1.5 px-4 relative flex items-center justify-between rounded bg-[#3C3C3F1A] dark:bg-[#F2F2F51A] after:content-[''] after:block after:absolute after:-bottom-3 after:left-4 after:border-t-[12.33px] after:border-t-[#3C3C3F1A] dark:after:border-t-[#F2F2F51A] after:border-r-[12.33px] after:border-r-transparent after:border-l-[12.33px] after:border-l-transparent",
    className,
  );
  return (
    <div className={rootClassName}>
      <div className="flex items-center gap-4">
        { icon }
        <div className="flex flex-1 flex-col py-2 gap-1">
          <Typography
            variant="body1/regular-400"
            className="text-[#455A64] dark:text-mui-gray-blue font-medium"
          >
            { title }
          </Typography>
          <Typography
            variant="body2/regular-400"
            className="text-mui-black-60 dark:text-mui-white-70"
          >
            { text }
          </Typography>
        </div>
      </div>
      {(hasAction || hasClose) && (
        <div className="flex items-center gap-4">
          {hasAction && (
            <Button
              variant="contained"
              size="small"
              className="w-[83px] h-[30px] !rounded hover:!bg-[#455A64] dark:hover:!bg-mui-gray-blue !bg-mui-gray-blue dark:!bg-[#90A4AE] !text-sm-13 !text-transform-none"
              onClick={actionCallback}
            >
              { buttonText }
            </Button>
          )}
          {hasClose && (
            <IconButton
              className="rounded-full !p-0.5"
              onClick={closeCallback}
            >
              <CloseIcon className="!w-4 !h-4 dark:text-mui-white-56 text-mui-black-56" />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

export default TextBanner;
