import { Avatar } from "@mui/material";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { Event } from "../../assets/interfaces/interfaces";
import { formatTimestamp, getSourceLogo, getSourceName } from "../../helpers";
import { Link } from "react-router-dom";
interface KeyEventProps {
  keyEvent: Event;
}

const KeyEvent = ({ keyEvent }: KeyEventProps) => {
  return (
    <div className="flex gap-x-4 text-TGPT-secondary hover:text-tgpt-secondary-main">
      <div>
        <Avatar
          key={keyEvent.news_timestamp}
          alt="News Source Logo"
          src={getSourceLogo(keyEvent.news_url)}
          variant="circular"
          className="!w-[24px] !h-[24px]"
        />
      </div>
      <div>
        <p className="body2 pb-2 text-mui-black-60 dark:text-mui-white-70">{keyEvent.headline}</p>
        <p className="caption">{formatTimestamp(keyEvent.news_timestamp)}</p>
        <Link className="flex gap-x-2 items-center" to={keyEvent.news_url} target="_blank">
          <InsertLinkRoundedIcon className="!h-4 !w-4" />
          <p className="caption">{getSourceName(keyEvent.news_url)}</p>
        </Link>

      </div>
    </div>
  );
};

export default KeyEvent;
