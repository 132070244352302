import React, { useState } from "react";
import { Box, TextField, Button, Typography, Checkbox } from "@mui/material";
import classNames from "classnames";
import TradeLogo from "../assets/images/finchatGPT/TradeLogo-D.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import Link from "@mui/material/Link";
import PhoneNumberInput from "./auth/PhoneNumberInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useSignup } from "./auth/useSignup";
import { showNotification } from "../redux/slices/notificationSlice";
import { FormData, ValidationResult } from "../assets/interfaces/interfaces";
import SocialBar from "./SocialBar";
import useEnhancedNavigate from "./Session/helpers";

const validateForm = ({ email, name, phoneNumber, password }: FormData): ValidationResult => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const errors: { email?: string; name?: string; phoneNumber?: string; password?: string } = {};
  let isValid = true;
  if (!email) {
    errors.email = "Email is required.";
    isValid = false;
  }
  else if (!emailRegex.test(email)) {
    errors.email = "Please enter a valid email address.";
    isValid = false;
  }

  if (!name) {
    errors.name = "Name is required.";
    isValid = false;
  }

  if (!phoneNumber) {
    errors.phoneNumber = "Phone number is required.";
    isValid = false;
  }
  else if (!isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number.";
    isValid = false;
  }

  if (password.length < 6) {
    errors.password = "Password must be at least 6 characters long.";
    isValid = false;
  }

  return { valid: isValid, errors };
};
const renderTextField = (label: string, value: string, onChange, error: string, type?: string) => (
  <TextField
    label={label}
    variant="outlined"
    value={value}
    onChange={onChange}
    className="w-[340px] mb-4"
    error={!!error}
    helperText={error}
    type={type}
  />
);
const renderPhoneField = (label: string, value: string, onChange, error: string) => (
  <PhoneNumberInput className="w-[340px]" label={label} error={!!error} helperText={error} value={value} onChange={onChange} />
);

const SignupPage: React.FC = () => {
  const navigate = useEnhancedNavigate(); // Use the enhanced navigate function
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(value);
  };
  const handleLogoClick = () => {
    navigate("/");
  };

  const signup = useSignup();
  const handleSignup = async (event) => {
    event.preventDefault();
    if (!isEmailEntered) {
      if (validateEmail(email)) {
        setIsEmailEntered(true);
        setEmailError("");
      }
      else {
        setEmailError("Please enter a valid email address.");
      }
      return;
    }
    const { valid, errors } = validateForm({ email, name, phoneNumber, password });
    // Update error states
    setEmailError(errors.email);
    setNameError(errors.name);
    setPhoneNumberError(errors.phoneNumber);
    setPasswordError(errors.password);
    if (!valid) return;
    try {
      await signup({ email, password, displayName: name, phoneNumber, landing: true });
      dispatch(showNotification({ message: "Sign Up Successful!", severity: "success", horizontal: "right" }));
    }
    catch (error) {
      // handle error
      dispatch(showNotification({ message: error.code.replace("auth/", "").replaceAll("-", " "), severity: "error", horizontal: "right" }));
    }
  };

  const handleLogin = () => {
    navigate("/signin");
  };
  return (
    <div className="flex flex-col min-h-screen w-full bg-white text-mui-black-87 overflow-hidden">
      {/* Header Section with Logo and Text */}
      <div className="flex items-center justify-center pt-12 mb-24 cursor-pointer" onClick={handleLogoClick}>
        <img src={TradeLogo} alt="TradeLogo" className="h-8 mr-2" />
        <span className="text-[20px] font-bold">
          TradeGPT
        </span>
      </div>
      <div className="flex justify-center h-full">
        <Box>
          <div className="w-[550px] min-h-[534px] relative bg-white rounded-[10px]">
            {/* Create Acccount text */}
            <div className="flex flex-col justify-center items-center">
              <span className="text-center text-[34px] leading-[42px]">
                Create your account
              </span>
            </div>
            <form className="pt-8 flex flex-col justify-start items-center gap-4">
              {renderTextField("Email", email, e => setEmail(e.target.value), emailError)}
              {isEmailEntered && (
                <>
                  {renderTextField("Name", name, e => setName(e.target.value), nameError)}
                  {renderPhoneField("Phone Number", phoneNumber, handlePhoneChange, phoneNumberError)}
                  {renderTextField("Password", password, e => setPassword(e.target.value), passwordError, "password")}
                  <div className="flex w-[340px] items-start gap-2">
                    <Checkbox
                      className={
                        classNames("!p-0",
                          {
                            "!text-TGPT-secondary-dark": isTermsAccepted,
                          },
                        )
                      }
                      checked={isTermsAccepted}
                      onChange={e => setIsTermsAccepted(e.target.checked)}
                    />
                    <span className="text-sm font-semibold">
                      By creating an account, I agree to TradeGPT’s
                      {" "}
                      {" "}
                      <Link className="!text-TGPT-secondary-dark" href="/policies/service-terms" target="_blank" underline="none">
                        Terms of Service
                      </Link>
                      ,
                      {" "}
                      <Link className="!text-TGPT-secondary-dark" href="/policies/privacy-policy" target="_blank" underline="none">
                        Privacy Policy
                      </Link>
                      , and
                      {" "}
                      <Link className="!text-TGPT-secondary-dark" href="/policies/legal-disclaimer" target="_blank" underline="none">
                        Legal Disclaimer
                      </Link>
                      .
                    </span>
                  </div>
                </>
              )}
              {
                !isEmailEntered
                && (
                  <Button
                    type="submit"
                    variant="contained"
                    className="w-[340px] h-[50px] text-white text-[15px] font-medium leading-[26px] !bg-TGPT-secondary-dark !normal-case"
                    onClick={handleSignup}
                  >
                    Continue
                  </Button>
                )
              }
              {
                isEmailEntered
                && (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isTermsAccepted}
                    className="w-[340px] h-[50px] text-white text-[15px] font-medium leading-[26px] disabled:!bg-mui-black-12 !bg-TGPT-secondary-dark !normal-case"
                    onClick={handleSignup}
                  >
                    Sign up
                  </Button>
                )
              }
              {/* Sign-up link */}
              <div className="flex flex-col justify-start items-center gap-4">
                <Typography variant="body1" className="text-center">
                  Already have an account?
                  {" "}
                  <span onClick={handleLogin} className="cursor-pointer text-[#1269ec] hover:underline">

                    Log in here

                  </span>
                </Typography>
              </div>
              {!isEmailEntered && (
                <SocialBar />
              )}
            </form>
          </div>
        </Box>
      </div>
      <footer className="flex justify-center items-center mt-auto p-6">
        <span className=" text-[#A3A3A3] hover:underline">
          <Link href="/policies/service-terms" color="inherit" underline="none">
            Terms of Service
          </Link>
        </span>
        <div className="px-4 text-black">
          |
        </div>
        <span className=" text-[#A3A3A3] hover:underline">
          <Link href="/policies/privacy-policy" color="inherit" underline="none">
            Privacy Policy
          </Link>
        </span>
        <div className="px-4 text-black">
          |
        </div>
        <span className=" text-gray-400 hover:underline">
          <Link href="/policies/legal-disclaimer" color="inherit" underline="none">
            Legal Disclaimer
          </Link>
        </span>
      </footer>
    </div>
  );
};

export default SignupPage;
