import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doSignOutClear } from "../../components/auth/helpers";

export enum ActivityName {
  IMAGE_PROMPT_TUTORIAL = "IMAGE_PROMPT_TUTORIAL",
}

export interface ActivityType {
  completed: boolean;
  prompt_after: string | null;
}

export interface UserActivitiesState {
  activities: Record<ActivityName, ActivityType>;
  isActivitiesLoaded: boolean;
}

export interface UpdateUserActivityArg {
  userId: string;
  activity: ActivityName;
  prompt_after_time?: boolean;
  completed_time?: string;
}

export interface FetchUserActivitiesArg {
  userId: string;
}

export interface UserAcitivitiesItem {
  user_id: string;
  activity: ActivityName;
  prompt_after_time: string | null;
  completed_time: string | null;
}

const initialState: UserActivitiesState = {
  activities: {
    [ActivityName.IMAGE_PROMPT_TUTORIAL]: {
      completed: false,
      prompt_after: null,
    },
  },
  isActivitiesLoaded: false,
};

export const updateUserActivity = createAsyncThunk(
  "user/updateUserActivity",
  async (arg: UpdateUserActivityArg, { rejectWithValue, dispatch }) => {
    try {
      const { userId, ...payload } = arg;
      const { data } = await axios.put(
        `${process.env.REACT_APP_SPL_URL}/v1/tradegpt-user-activities/${userId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const getUserActivities = createAsyncThunk(
  "user/fetchUserActivities",
  async (arg: FetchUserActivitiesArg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/v1/tradegpt-user-activities/${arg.userId}`, { withCredentials: true },
      );
      return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 403) {
          doSignOutClear(dispatch);
          return;
        }
        if (error.response) {
          return rejectWithValue(error.response.data);
        }
      }
      return rejectWithValue("Unexpected error occurred");
    }
  },
);

export const userActivitiesSlice = createSlice({
  name: "userActivities",
  initialState,
  reducers: {
    updateUserActivities: (state, action) => {
      state.activities[action.payload.activityName] = {
        ...state.activities[action.payload.activityName],
        ...action.payload.activity,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserActivities.fulfilled, (state, action) => {
        for (const activity of action.payload) {
          const key = activity.activity;
          if (state.activities[key]) {
            state.activities[key] = {
              completed: Boolean(activity.completed_time),
              prompt_after: activity.prompt_after_time,
            };
          }
        }
        state.isActivitiesLoaded = true;
      })
      .addCase(getUserActivities.rejected, (state) => {
        state.isActivitiesLoaded = true;
      });
  },
});

export const { updateUserActivities } = userActivitiesSlice.actions;
export default userActivitiesSlice.reducer;
