import { useId } from "react";
import { useSelector } from "react-redux";
type NewsSummaryHeaderProps = {
  text: string;
  width?: number;
  height?: number;
  bgColor?: string;
  startColor?: string;
  endColor?: string;
  fontSize?: number;
  letterSpacing?: number;
};
export const NewsSummaryAnimated = ({ text, bgColor, width = 212, height = 21, fontSize = 14 }: NewsSummaryHeaderProps) => {
  const maskId = useId();
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);

  return (
    <div
      className="mx-[1px] my-1 bg-gradient-animated"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <div
        className="white-mask mask-url"
        style={
          {
            "backgroundColor": isDarkMode ? bgColor : "white",
            "--mask-url": `url(#${maskId})`,
          } as React.CSSProperties
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width + 2} ${height + 2}`} preserveAspectRatio="xMidYMid meet" width="100%" height="100%">
          <defs>
            <mask id={maskId}>
              <rect width="100%" height="100%" fill="#fff" />
              <path
                d="M17.045 14.2925L17.835 16.0425L19.585 16.8325C19.975 17.0125 19.975 17.5625 19.585 17.7425L17.835 18.5325L17.045 20.2925C16.865 20.6825 16.315 20.6825 16.135 20.2925L15.345 18.5425L13.585 17.7525C13.195 17.5725 13.195 17.0225 13.585 16.8425L15.335 16.0525L16.125 14.2925C16.305 13.9025 16.865 13.9025 17.045 14.2925ZM9.085 12.7925L7.495 16.2925C7.145 17.0725 6.025 17.0725 5.675 16.2925L4.085 12.7925L0.585 11.2025C-0.195 10.8425 -0.195 9.7325 0.585 9.3825L4.085 7.7925L5.675 4.2925C6.035 3.5125 7.145 3.5125 7.495 4.2925L9.085 7.7925L12.585 9.3825C13.365 9.7425 13.365 10.8525 12.585 11.2025L9.085 12.7925ZM16.125 6.2925L15.335 4.5425L13.585 3.7525C13.195 3.5725 13.195 3.0225 13.585 2.8425L15.335 2.0525L16.125 0.2925C16.305 -0.0974998 16.855 -0.0974998 17.035 0.2925L17.825 2.0425L19.585 2.8325C19.975 3.0125 19.975 3.5625 19.585 3.7425L17.835 4.5325L17.045 6.2925C16.865 6.6825 16.305 6.6825 16.125 6.2925Z"
                transform="translate(4, 1)"
                fill="#000"
              />
              <text
                x="30"
                y={height / 2 + 2}
                fontSize={fontSize}
                fill="black"
                dominantBaseline="middle"
                textAnchor="start"
              >
                {text}
              </text>
            </mask>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export const NewsSummaryStatic = ({ text, startColor, endColor, width, height, fontSize = 14, letterSpacing = 0 }: NewsSummaryHeaderProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
      <defs>
        <linearGradient id="gradient-fill" x1="0" y1="0" x2="193" y2="0" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
      <path
        d="M26.6304 22.8579L27.5899 24.9832L29.7152 25.9427C30.1889 26.1613 30.1889 26.8292 29.7152 27.0478L27.5899 28.0073L26.6304 30.1448C26.4118 30.6184 25.7439 30.6184 25.5253 30.1448L24.5658 28.0194L22.4284 27.06C21.9547 26.8414 21.9547 26.1734 22.4284 25.9548L24.5537 24.9954L25.5131 22.8579C25.7317 22.3843 26.4118 22.3843 26.6304 22.8579ZM16.9632 21.0362L15.0322 25.2869C14.6071 26.2342 13.2469 26.2342 12.8218 25.2869L10.8908 21.0362L6.64016 19.1052C5.69286 18.668 5.69286 17.3199 6.64016 16.8948L10.8908 14.9638L12.8218 10.7131C13.2591 9.76585 14.6071 9.76585 15.0322 10.7131L16.9632 14.9638L21.2139 16.8948C22.1612 17.332 22.1612 18.6801 21.2139 19.1052L16.9632 21.0362ZM25.5131 13.1421L24.5537 11.0168L22.4284 10.0573C21.9547 9.83872 21.9547 9.17075 22.4284 8.95215L24.5537 7.99271L25.5131 5.85523C25.7317 5.38159 26.3997 5.38159 26.6183 5.85523L27.5777 7.98057L29.7152 8.94001C30.1889 9.15861 30.1889 9.82657 29.7152 10.0452L27.5899 11.0046L26.6304 13.1421C26.4118 13.6157 25.7317 13.6157 25.5131 13.1421Z"
        fill="url(#gradient-fill)"
      />
      <text
        x="40"
        y="18"
        fill="url(#gradient-fill)"
        fontSize={fontSize}
        dominantBaseline="middle"
        textAnchor="start"
        style={{ letterSpacing: `${letterSpacing}px` }}
      >
        {text}
      </text>
    </svg>
  );
};
