import {
  GeneralModalProps,
  RootState,
} from "../../assets/interfaces/interfaces";
import { Modal, IconButton, Button, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { shareTransform } from "../../helpers";
import ChatMessage from "../ChatMessage";
import { AppDispatch } from "../../redux/store";
import { fetchSharedSession } from "../../redux/slices/sharedSessionSlice";
import { showNotification } from "../../redux/slices/notificationSlice";
import { fetchChangeTitle } from "../../redux/slices/changeTitleSlice";
// import useWindowDimensions from '../../hooks/useWindowDimensions';
import { EditIcon } from "../Plans/icons/GPTIcons/EditIcon";
import { CopyLinkIcon } from "../Plans/icons/GPTIcons/CopyLinkIcon";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";
import ImageModal from "../ImageModal";

const SharePreviewModal: React.FC<GeneralModalProps> = ({
  open,
  handleClose,
  session_id = "",
}) => {
  const [messages, setMessages] = useState([]);

  const user = useSelector((state: { auth }) => state.auth.currentUser);

  const dispatch = useDispatch<AppDispatch>();

  const { pastMessages } = useSelector(
    (state: RootState) => state.sharedSession,
  );
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(fetchSharedSession({ sessionId: session_id }));
      setRename(false);
      setTitle("");
    }
  }, [session_id, dispatch, open]);

  useEffect(() => {
    if (pastMessages.length) {
      const formattedPastChat = shareTransform(pastMessages);
      setMessages(formattedPastChat);
    }
  }, [pastMessages, user]);

  const copyToClipboard = () => {
    const domain = window.location.origin;
    let encodedTitle = title;

    if (title !== "") {
      encodedTitle = btoa(title);
    }

    const linkToCopy = `${domain}/share/${session_id}/${encodedTitle}`;
    navigator.clipboard.writeText(linkToCopy);
    dispatch(
      showNotification({
        message: "Link copied to clipboard!",
        severity: "success",
        horizontal: "right",
      }),
    );
    handleClose();
  };
  const sessionTitle = useMemo(() => {
    if (messages[0] && messages[0]?.title) {
      return messages[0]?.title;
    }
    return "";
  }, [messages]);
  const session_date = useMemo(() => {
    if (!!messages[0] && messages[0]?.date) {
      // Remove quotes from the title
      return messages[0].date;
    }
    return "";
  }, [messages]);
  const [title, setTitle] = useState("");
  const [rename, setRename] = useState(false);
  const renameInputRef = useRef<HTMLInputElement>(null); // Specify the ref type as HTMLInputElement
  // Set focus to the input when renaming
  useEffect(() => {
    if (rename && renameInputRef.current) {
      renameInputRef?.current.focus();
      renameInputRef?.current.select();
    }
  }, [rename, renameInputRef]);
  const changeTitleReq = () => {
    dispatch(
      fetchChangeTitle({
        session_id,
        title,
        userId: user?.userId,
        fetchSession: true,
      }),
    );
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      changeTitleReq();
      setRename(false); // Set rename to false when Enter is pressed
      // Additional actions can be added here if needed
    }
    if (e.key === "Escape") {
      setRename(false);
    }
  };
  const finaltitle = (title || sessionTitle).replace(/['"]/g, "");

  function onImageModalClose() {
    setIsImageModalOpen(false);
    setImageSrc("");
  }

  function handleImageClick(imageSrc) {
    setImageSrc(imageSrc);
    setIsImageModalOpen(true);
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col gap-y-6 shadow w-modal-mobile sm:w-modal-md rounded-lg p-6 bg-white text-mui-black-87 dark:bg-background/paper-elevation-1 dark:text-white">
            <section className="flex flex-col gap-y-4 relative">
              <IconButton
                size="small"
                onClick={handleClose}
                className="cross-icon"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <CrossIcon className="fill-current" />
              </IconButton>
              <h6 className="font-medium">Share link to Chat</h6>
              <Divider sx={{ width: "100%" }} />
              <div className="body2">
                <p className="pb-2">
                  Messages you send after creating your link won’t be shared.
                </p>
                <p>Anyone with the URL will be able to view the shared chat.</p>
              </div>
            </section>

            <section className="w-[279px] sm:w-[462px] border border-mui-black-12 dark:border-mui-white-12 rounded flex flex-col">
              <div className="overflow-y-scroll p-4 h-[280px]">
                {messages.map((message: string, index: number) => (
                  <ChatMessage message={message} key={index} onImageClick={handleImageClick} isPreview />
                ))}
              </div>
              <div className="dark:bg-background/paper-elevation-8 border-t dark:border-t-mui-white-12 p-4 flex items-center">
                <div className="flex flex-col flex-1 gap-y-2">
                  {/* editable title */}
                  <div className="flex items-center">
                    {rename
                      ? (
                          <input
                            onKeyDown={handleKeyPress}
                            ref={renameInputRef}
                            className="ps-1 dark:text-white border bg-transparent"
                            placeholder={finaltitle}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                          />
                        )
                      : (
                          <p className="body2">{finaltitle || "No data"}</p>
                        )}
                    {!rename && finaltitle && (
                      <IconButton
                        onClick={() => setRename(true)}
                        className="cross-icon"
                      >
                        <EditIcon className="fill-current" />
                      </IconButton>
                    )}
                  </div>
                  <div className="body2 text-mui-black-60 dark:text-mui-white-70">
                    {session_date || "No date data"}
                  </div>
                </div>
              </div>
            </section>

            <div className="w-full sm:w-2/3 self-center">
              <Button
                onClick={copyToClipboard}
                variant="contained"
                className="button-primary flex gap-x-2"
                size="large"
                fullWidth
              >
                <CopyLinkIcon />
                {rename ? "Update and copy link" : "Copy Link"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <ImageModal open={isImageModalOpen} onClose={onImageModalClose} imageSrc={imageSrc} />
    </>
  );
};

export default SharePreviewModal;
