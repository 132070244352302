import { useEffect, useState, useCallback, useRef } from "react";
import { Ticker, TickerInfo } from "../../assets/interfaces/interfaces";
import { Button, CircularProgress, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { MODE } from "./constants";
import SparklineChart from "../Shared/Sparkline";
import moment from "moment";
import { fetchSparkline } from "../api/fetchSparkline";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { removeTicker, setWatchlistDrawerState } from "../../redux/slices/watchlistSlice";
import { PromptList, getPrompt } from "./constants";
import "./index.scss";
import Price from "./Price";
import { RootState } from "../../assets/interfaces/interfaces";
import { usePrice } from "./hooks";
import { processSparklineData } from "../../helpers";
import { DragIndicatorRounded, PlaylistAddRounded, PlaylistRemoveRounded } from "@mui/icons-material";
import { updateSelectedTickerProps } from "../../redux/slices/newsSummarySlice";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import classNames from "classnames";

type TickerProps = {
  ticker: Ticker;
  isSelected?: boolean;
  mode: `${MODE}`;
  tickerData: TickerInfo | null;
  className?: string;
  onDragOver?: (e, id: string) => void;
  onDrop?: (e, id: string) => void;
  onDragStart?: (e, id: string) => void;
  onDragLeave?: () => void;
  handlePrompt?: (msg: string) => void;
  onAddTicker?: (string) => void;
  onVisibilityChange?: (ticker: string, isVisible: boolean) => void;
};

export default function TickerCard({
  ticker,
  mode,
  tickerData,
  className,
  onDragStart,
  onDrop,
  onDragOver,
  handlePrompt,
  onDragLeave,
  onAddTicker,
  onVisibilityChange,
}: TickerProps) {
  const [series, setSeries] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const { isMutating, tickers } = useSelector(
    (state: { watchlist }) => state.watchlist,
  );
  const isMessageStreaming = useSelector((state: RootState) => state?.finchat?.loading);
  const [isTickerInWatchlist, setIsTickerInWatchlist] = useState(
    tickers.some(existingTicker => existingTicker.ticker === ticker?.ticker) || false,
  );

  const {
    selectedTickerProps,
  } = useSelector((state: { newsSummary }) => state.newsSummary);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { priceChange, isFetchPrevCloseLoading } = usePrice(ticker?.ticker, tickerData?.prev_close_price, tickerData?.last_price);

  const handleButtonClick = useCallback(
    (event, promptType, tickerName) => {
      event.stopPropagation();
      const prompt = getPrompt(promptType, tickerName);
      handlePrompt(prompt);
      if (isMobile) {
        dispatch(setWatchlistDrawerState(false));
      }
    },
    [handlePrompt],
  );

  const toTime = moment().format("YYYY-MM-DD");
  const fromTime = moment().subtract(7, "days").format("YYYY-MM-DD");

  const fetchSparklineData = async (signal) => {
    try {
      const data = await fetchSparkline(ticker?.ticker, fromTime, toTime, signal);
      processSparklineData(data, setSeries);
    }
    catch (error) {
      if (error.name !== "CanceledError") {
        console.error("Error fetching sparkline data:", error);
        setSeries("Data unavailable");
      }
    }
  };

  const onClickDelete = useCallback((e, id: string) => {
    e.preventDefault();
    dispatch(removeTicker(id));
  }, []);

  const onClickAddTicker = useCallback(async () => {
    if (isTickerInWatchlist) {
      return;
    }
    setIsTickerInWatchlist(true); // Optimistic update
    try {
      await onAddTicker(ticker?.ticker);
    }
    catch (e) {
      console.error("Error adding ticker to watchlist:", e);
      setIsTickerInWatchlist(false);
    }
  }, [onAddTicker, ticker]);

  const tickerRef = useRef(null);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchDataIfVisible = async ([entry]) => {
      if (entry.isIntersecting) {
        if (tickerRef.current) {
          observer.unobserve(tickerRef.current); // can stop observing the ref once fetching starts
        }
        await fetchSparklineData(signal);
      }
      onVisibilityChange(ticker.ticker, entry.isIntersecting);
    };

    const observer = new IntersectionObserver(fetchDataIfVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });

    if (tickerRef.current) {
      observer.observe(tickerRef.current);
    }

    return () => {
      observer.disconnect();
      abortController.abort();
    };
  }, [ticker]);

  // for expanded ticker view
  useEffect(() => {
    const fetchSparklineForNewsView = async () => {
      if (mode === MODE.NEWS && selectedTickerProps.ticker) {
        const data = await fetchSparkline(selectedTickerProps.ticker, fromTime, toTime);
        processSparklineData(data, setSeries);
      }
    };
    fetchSparklineForNewsView();
  }, [mode, selectedTickerProps.ticker]);
  const tickerName = ticker.name ?? tickerData?.name;

  return (
    <>
      {!selectedTickerProps.ticker && (
        <div
          ref={tickerRef}
          className={`flex flex-col w-full draggable-item cursor-pointer ${className}`}
          draggable={!isMutating && mode === MODE.LIST_MANAGEMENT}
          onDragOver={e => onDragOver(e, ticker.ticker)}
          onDrop={e => onDrop(e, ticker.ticker)}
          onDragStart={(e) => {
            onDragStart(e, ticker.ticker);
          }}
          onDragLeave={onDragLeave}
          onClick={() => {
            if (mode !== MODE.LIST_MANAGEMENT) {
              dispatch(updateSelectedTickerProps({
                ticker: ticker.ticker,
                isExpandKeyEvents: false,
              }));
            }
          }}
        >
          <div className="flex w-full items-center">
            {mode === MODE.LIST_MANAGEMENT && (
              <IconButton
                size="small"
                onClick={e => onClickDelete(e, ticker.ticker)}
                className="cross-icon"
                disabled={isMutating}
              >
                <PlaylistRemoveRounded className="!fill-error-dark dark:!fill-text-error-main" />
              </IconButton>
            )}
            {mode === MODE.SEARCH && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickAddTicker();
                }}
                disabled={isTickerInWatchlist}
                className="relative w-8 h-8 overflow-hidden"
              >
                <div
                  className={classNames("absolute inset-0 transition-all duration-300 ease-in-out", {
                    "opacity-100 scale-100": isTickerInWatchlist,
                    "opacity-0 scale-50": !isTickerInWatchlist,
                  })}
                >
                  <PlaylistAddCheckCircleRoundedIcon className="!h-6 !w-6 !fill-tgpt-secondary-main" />
                </div>

                <div
                  className={classNames("absolute inset-0 transition-all duration-300 ease-in-out", {
                    "opacity-0 scale-50": isTickerInWatchlist,
                    "opacity-100 scale-100": !isTickerInWatchlist,
                  })}
                >
                  <PlaylistAddRounded className="!fill-mui-black-56 dark:!fill-mui-white-56" />
                </div>
              </IconButton>
            )}

            <div className="flex justify-between p-2 items-center body1 h-[76px] w-full sm:w-[328px]">
              <div className="flex flex-col w-[90px]">
                <p className="body1 font-semibold">{ticker.ticker}</p>
                <Tooltip
                  title={tickerName}
                  placement="left"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <p className="body2 text-mui-black-60 dark:text-mui-white-70 whitespace-nowrap overflow-hidden text-ellipsis">
                    {tickerName}
                  </p>
                </Tooltip>
              </div>
              <div className={mode === MODE.VIEW ? "ps-2" : "w-[90px]"}>
                {series === null || isFetchPrevCloseLoading
                  ? (
                      <div className="flex justify-center items-center">
                        <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16} />
                      </div>
                    )
                  : Array.isArray(series) && priceChange !== null
                    ? (
                        <div className="transition-all duration-500">
                          <SparklineChart series={series} priceChange={priceChange} />
                        </div>
                      )
                    : (
                        <p className="text-mui-black-60 dark:text-mui-white-70 text-xs text-center">
                          Data
                          <br />
                          Unavailable
                        </p>
                      )}
              </div>
              <div className={`flex flex-col items-end ${mode !== MODE.SEARCH ? "w-[90px]" : "w-[70px]"}`}>
                <Price ticker={ticker.ticker} prevClose={tickerData?.prev_close_price} lastPrice={tickerData?.last_price} />
              </div>
            </div>
            {mode === MODE.LIST_MANAGEMENT && (
              <div className="cursor-grab drag-handle">
                <DragIndicatorRounded className="!fill-mui-black-56 dark:!fill-mui-white-56 !h-5" />
              </div>
            )}
          </div>
        </div>
      )}
      {selectedTickerProps.ticker && (
        <div className="flex flex-col">
          <section className="flex items-center justify-between">
            <div>
              <h6 className="font-medium">{selectedTickerProps.ticker}</h6>
              <p className="body2 text-mui-black-60 dark:text-mui-white-70">{tickerName}</p>
            </div>
            <Price ticker={ticker.ticker} prevClose={tickerData?.prev_close_price} lastPrice={tickerData?.last_price} showValueDiff={true} />
          </section>
          <section className="pt-[12px]">
            <div className="h-20 w-[328px] flex items-center justify-center">
              {series === null || isFetchPrevCloseLoading
                ? <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16} />

                : Array.isArray(series) && priceChange !== null
                  ? (
                      <div className="transition-all duration-500">
                        <SparklineChart series={series} priceChange={priceChange} height={80} width={328} isHoverable={true} />
                      </div>
                    )
                  : (
                      <p className="text-mui-black-60 dark:text-mui-white-70 text-xs text-center">
                        Data
                        <br />
                        Unavailable
                      </p>
                    )}
            </div>
            <div className="px-4 py-6">
              Suggested Prompts:
              <div className="pt-4 flex flex-wrap gap-1">
                {PromptList.map((type, index) => (
                  <Button
                    key={index}
                    className="button-outlined"
                    variant="outlined"
                    color="inherit"
                    size="small"
                    // Disable prompt button when name is not loaded or a prompt query is going on
                    disabled={!selectedTickerProps.ticker || isMessageStreaming}
                    onClick={e => handleButtonClick(e, type, selectedTickerProps.ticker)}
                  >
                    <p className="tracking-[0.46px]">{type}</p>
                  </Button>
                ))}
              </div>
            </div>
          </section>

        </div>
      )}
    </>
  );
}
