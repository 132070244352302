import { List, ListItem, ListItemText } from "@mui/material";
import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";
import withScrollToTop from "../../helpers/ScrollToTop";

function TakeDownPolicy() {
  return (
    <TermsAndPoliciesLayout type="take_down" headerTitle="Take Down Procedure">
      <p className="font-bold text-[28px]">1. Purpose and Scope</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                This Take Down Procedure establishes the process for handling requests related to
                the removal of your content, chat history, user-generated files, API outputs, or
                third-party plugin interactions (“Content”) for TradeGPT.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">2. Permissible Reasons for Take Down Requests</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                You may submit a request to remove your Content at any time and for any reason.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">3. Take Down Request Submission</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Take down requests may be submitted at any time via the official reporting channels:
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Email:
                {" "}
                <a className="text-[#007AFF]" href="mailto:support@tradealgo.com">support@tradealgo.com</a>
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Requests must include:
              </p>
            )}
          />
          <List sx={{ listStyleType: "decimal", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item", paddingY: "0px" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Requester's details (full name, organization, contact info).
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0px" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Description of the content (exact location, date, time of occurrence).
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingY: "0px" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Proof of ownership or affected party authorization (if applicable).
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">4. Review & Decision Process</p>
      <div className="overflow-x-auto my-4">
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-[#F5F5F7] border-b border-gray-300">
              <th className="px-6 py-3 text-left font-bold text-[#1C1C1E]">Step</th>
              <th className="px-6 py-3 text-left font-bold text-[#1C1C1E]">Action</th>
              <th className="px-6 py-3 text-left font-bold text-[#1C1C1E]">Timeline</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">1. Acknowledgment</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Confirm receipt of request</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">By close of next business day from date of request</td>
            </tr>
            <tr className="border-b border-gray-200 bg-[#F9F9FA]">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">2. Initial Assessment</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Determine validity & urgency</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 3 business days from date of request</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">3. Legal & Compliance Review</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Verify legal grounds (if applicable)</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 7 business days from data of request</td>
            </tr>
            <tr className="border-b border-gray-200 bg-[#F9F9FA]">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">4. Decision (& Notification of Decision to Affected Party)</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Inform user & request response (if required)</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 15 business days from date of request</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">5. Appeal Process</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Time period for user to appeal</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 10 business days from date of decision</td>
            </tr>
            <tr className="border-b border-gray-200 bg-[#F9F9FA]">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">6. Appeal Outcome (& Notification of Appeal to Affected Party)</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Inform user of appeal outcome</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 15 business days from date of decision</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">7. Implementation</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Removal of Content</td>
              <td className="px-6 py-4 font-['Inter'] text-[#1C1C1E]">Within 30 days of outcome of appeal/decision (if there is no appeal)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Please note that all timelines outlined in this procedure are estimates and may be
                subject to delays due to legal, technical, or operational considerations. TA Fintech
                Inc. will make reasonable efforts to process requests within the stated timeframes
                but does not guarantee strict adherence to them.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">5. Notification of Take Down Requests of Your Content</p>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Approved Requests: The requester will receive confirmation of content removal.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Denied Requests: The requester will be informed of the reason for rejection.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">6. Appeals</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Users may submit an appeal within 10 days after receiving the outcome of their
                take down request.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                The appeal must include a detailed explanation, supporting evidence, and legal
                justifications (if applicable). If an appeal is granted, the Content will be
                removed unless legal obligations or security reasons require us to keep the
                Content. If the appeal is denied, users may seek resolution through legal channels.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">7. Permanent Deletion of Content from Our Systems</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Content that is deemed by us to have sufficient grounds for removal would be
                permanently deleted from our systems within 30 days, unless they have previously
                been de-identified and disassociated from your account or we have to keep them
                for security or legal reasons.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">8. Compliance, Record-Keeping and Disciplinary Measures</p>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Logs of take down requests are retained for 12 months for legal compliance.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Repeat offenders may face account suspension or permanent bans.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Regulatory authorities will be notified as required.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">9. Our legal liability</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                TA Fintech Inc. is not liable for any claims arising from content removal decisions made
                in good faith based on this policy.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We are not responsible for damages resulting from delayed or denied takedown requests
                unless required by law.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">10. Contact for Escalations</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                For unresolved disputes, please email
                {" "}
                {" "}
                <a className="text-[#007AFF]" href="mailto:support@tradealgo.com">support@tradealgo.com</a>
                {" "}
                and
                address it to TA Fintech Inc. – Compliance Team.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Thank you for the opportunity to address your concerns.
              </p>
            )}
          />
        </ListItem>
      </List>
    </TermsAndPoliciesLayout>
  );
}

export default withScrollToTop(TakeDownPolicy);
