// src/index.tsx
import React from "react";
import "./darkmode.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import "github-markdown-css";
import "firebase/firestore";
import { firebaseApp } from "./components/auth/firebase";
import AuthListener from "./components/auth/AuthListener";
import { createRoot } from "react-dom/client";
import AppRoutes from "./Routes";
import ErrorBoundary from "./components/Errorpage";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import "./index.css";
import ReactGA from "react-ga4";
import { TRACKING_ID } from "./analytics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// init firebase
// eslint-disable-next-line
const fb = firebaseApp
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}
// react v18
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthListener />
        <ThemeProvider theme={theme}>
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>,
  // </React.StrictMode>
);
