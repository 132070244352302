import { signInWithGoogle } from "../components/auth/google";
import { signInWithMicrosoft } from "../components/auth/microsoft";
import { signInWithApple } from "../components/auth/apple";
import Apple from "../assets/images/finchatGPT/Apple.png";
import Google from "../assets/images/finchatGPT/Google.png";
import Microsoft from "../assets/images/finchatGPT/Microsoft.png";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { useEffect } from "react";
import useEnhancedNavigate, { injectTikTokScript, removeTikTokScript } from "./Session/helpers";

interface OauthProps {
  handleClose?;
  navigate?;
}

export const OauthButtons: React.FC<OauthProps> = ({ handleClose = () => { }, navigate }) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    // Inject the script when the component mounts
    injectTikTokScript();
    return () => {
      // Remove the script when the component unmounts
      removeTikTokScript();
    };
  }, []);

  return (
    <div className="opacity-80 flex flex-col justify-start items-start gap-2 button-container pt-4">
      <Button
        variant="outlined"
        onClick={() => signInWithGoogle(handleClose, dispatch, navigate)}
        startIcon={<img alt="google logo" src={Google} className="w-[24px]" />}
        className="w-[340px] h-[50px] px-4 py-[5px] rounded-md border border-zinc-200 justify-start items-center gap-2.5 inline-flex"
      >
        Continue with Google
      </Button>
      <Button
        variant="outlined"
        onClick={() => signInWithMicrosoft(handleClose, dispatch, navigate)}
        startIcon={<img alt="Microsoft logo" src={Microsoft} className="w-[24px]" />}
        className="w-[340px] h-[50px] px-4 py-[5px] rounded-md border border-zinc-200 justify-start items-center gap-2.5 inline-flex"
      >
        Continue with Microsoft
      </Button>
      <Button
        onClick={() => signInWithApple(handleClose, dispatch, navigate)}
        variant="outlined"
        startIcon={<img alt="Apple logo" src={Apple} className="w-[24px]" />}
        className="w-[340px] h-[50px] px-4 py-[5px] rounded-md border border-zinc-200 justify-start items-center gap-2.5 inline-flex"
      >
        Continue with Apple
      </Button>
    </div>
  );
};

const SocialBar: React.FC = () => {
  const navigate = useEnhancedNavigate();
  return (
    <div>
      <div className="w-[340px] flex justify-start items-center">
        <div className="w-[340px] flex items-center justify-center">
          <hr className="flex-grow" />
          <span className="text-[12px] px-2">OR</span>
          <hr className="flex-grow" />
        </div>
      </div>
      {/* Social media sign-in buttons */}
      <OauthButtons navigate={navigate} />
    </div>
  );
};

export default SocialBar;
