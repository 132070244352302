import { useMemo, useState } from "react";
import NewsSummaryCard from "./NewsSummaryCard";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Button, IconButton, Tooltip } from "@mui/material";
import { NewsSummaryAnimated } from "../Icons/NewsSummaryAnimated";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { LLMSummaryNews } from "../../assets/interfaces/interfaces";
import ErrorMessage from "./ErrorMessage";
import { daysSince } from "../../helpers";

type NewsSummaryType = {
  news: LLMSummaryNews[];
  query: string;
  handleSendMessage?: (string) => void;
};

const getGridClass = (count) => {
  if (count === 1) return "lg:grid-cols-1";
  if (count === 2 || count === 4) return "lg:grid-cols-2";
  if (count === 3 || count === 6) return "lg:grid-cols-3";
  if (count === 5) return "lg:grid-cols-2";
};

const NewsSummary = ({ news, query, handleSendMessage }: NewsSummaryType) => {
  const [showAllCards, setShowAllCards] = useState<boolean>(news.length > 3 ? false : true);

  const onRegenerateMessage = () => {
    handleSendMessage(query);
  };

  const displayedRowOfNews = useMemo(() => {
    let displayedNews;
    if (!showAllCards && news.length === 4) {
      displayedNews = news.slice(0, 2);
    }
    else if (!showAllCards) {
      displayedNews = news.slice(0, 3);
    }
    else {
      displayedNews = news;
    }
    const rows = [];
    let row = [];
    displayedNews.forEach((news, index) => {
      row.push(news);
      // for 5 news, arrange them with 3 news in the first row and 2 news in the second row
      if ((displayedNews.length === 5 && row.length === (index < 3 ? 3 : 2)) || (displayedNews.length === 4 && row.length === 2)) {
        rows.push(row);
        row = [];
      }
    });
    if (row.length > 0) rows.push(row);

    return rows;
  }, [news, showAllCards]);

  const { hasExpiredSummary, allExpired } = useMemo(() => {
    const totalRows = displayedRowOfNews.flat();
    const expiredRows = totalRows.filter(summary => daysSince(summary.create_timestamp) > 7);

    return {
      hasExpiredSummary: expiredRows.length > 0,
      allExpired: expiredRows.length === totalRows.length,
    };
  }, [displayedRowOfNews]);

  return (
    <div className="flex flex-col gap-y-4 pt-1">
      <div className="body2 font-semibold flex items-center justify-between">
        <div className="flex items-center">
          <NewsSummaryAnimated text="TradeGPT News Summary" bgColor="rgba(30, 30, 30, 1)" />
          <Tooltip
            placement="top"
            arrow
            title={<p className="text-center text-xs">News summaries will only be accessible for up to 7 days from their creation date.</p>}
            classes={{
              tooltip: "!max-w-[255px]",
            }}
          >
            <IconButton>
              <ErrorOutlineRoundedIcon className="!w-4 !h-4 dark:fill-mui-white-56" />
            </IconButton>
          </Tooltip>
        </div>
        {news.length > 3 && (
          <Button variant="text" className="button-link dark:text-mui-white-70" onClick={() => setShowAllCards(!showAllCards)}>
            {showAllCards ? "Show less" : "Show more"}
            {showAllCards ? <UnfoldLessRoundedIcon className="!w-4 !h-4 ms-2" /> : <UnfoldMoreRoundedIcon className="!w-4 !h-4 ms-2" />}
          </Button>
        )}
      </div>

      {hasExpiredSummary && <ErrorMessage allExpired={allExpired} handleRegenerate={onRegenerateMessage} />}

      <section>
        {displayedRowOfNews.length > 0
        && displayedRowOfNews.map((row, rowIndex) => (
          <div
            key={`row-${rowIndex}`}
            className={`grid gap-2 grid-cols-1 sm:grid-cols-2 ${getGridClass(row.length)} auto-rows-fr mb-2`}
          >
            {row.map(summary => (
              <div
                key={summary.ticker}
              >
                <NewsSummaryCard newsSummary={summary} allExpired={allExpired} />
              </div>
            ))}
          </div>
        ))}
      </section>

    </div>
  );
};

export default NewsSummary;
