import { IconButton } from "@mui/material";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";

const ResizableIcon = ({ onClick, isExpanded }: { onClick?: () => void; isExpanded?: boolean }) => {
  return (
    <IconButton
      className="!absolute !bottom-2 !right-2 !z-10 !bg-white dark:!bg-background/paper-elevation-4"
      onClick={onClick}
    >
      {isExpanded ? <CloseFullscreenRoundedIcon className="w-5 h-5 p-[5px] dark:!fill-mui-white-56" /> : <OpenInFullRoundedIcon className="w-5 h-5 p-[5px] dark:!fill-mui-white-56" />}
    </IconButton>
  );
};

export default ResizableIcon;
