import axios from "axios";

export const fetchLatestNews = async (tickers: string[]) => {
  try {
    const tickersString = tickers.join(",");

    const { data } = await axios.get(
      `${process.env.REACT_APP_SPL_URL}/v1/news/summary?ticker=${tickersString}`,
      { withCredentials: true },
    );
    return data?.news;
  }
  catch (error) {
    console.error("Error fetching latest news:", error);
    throw error;
  }
};

export const fetchAllNewsForTicker = async (ticker: string) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SPL_URL}/v1/news/past_summary/${ticker}`,
      { withCredentials: true },
    );

    return data?.news?.sort((a, b) =>
      new Date(b.create_timestamp).getTime() - new Date(a.create_timestamp).getTime(),
    );
  }
  catch (error) {
    console.error("Error fetching past news", error);
    throw error;
  }
};
