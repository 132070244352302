import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChangeLog, ChangeLogsState } from "../../assets/interfaces/interfaces";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const initialState: ChangeLogsState = {
  lastViewed: cookies.get("changeLogs-lastViewed", { doNotParse: true }) as string | null,
  logs: [],
  isLoading: false,
};

export const fetchLogs = createAsyncThunk("changeLogs/fetchLogs",
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SPL_URL}/tradegpt-changelog`,
      );
      return data;
    }
    catch (err) {
      return rejectWithValue(err);
    }
  },
);

const ChangeLogsSlice = createSlice({
  name: "changeLogs",
  initialState,
  reducers: {
    setLastViewed: (state, action: PayloadAction<string>) => {
      state.lastViewed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.fulfilled, (state, action: PayloadAction<ChangeLog[]>) => {
        state.logs = action.payload ?? [];
        state.isLoading = false;
      })
      .addCase(fetchLogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLogs.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setLastViewed } = ChangeLogsSlice.actions;
export default ChangeLogsSlice.reducer;
