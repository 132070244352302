import React, { useState, FormEvent } from "react";
import { Box, TextField, Button, Typography, InputAdornment, IconButton } from "@mui/material";
import TradeLogo from "../assets/images/finchatGPT/TradeLogo-D.png";
import { useDispatch } from "react-redux";
import Link from "@mui/material/Link";
import { ValidationResult } from "../assets/interfaces/interfaces";
import { showNotification } from "../redux/slices/notificationSlice";
import { useSignin } from "./auth/useSignIn";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SocialBar from "./SocialBar";
import useEnhancedNavigate from "./Session/helpers";

const validateSignInForm = (email: string, password: string): ValidationResult => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const errors = { email: "", password: "" };
  let isValid = true;

  if (!email) {
    errors.email = "Email is required.";
    isValid = false;
  }
  else if (!emailRegex.test(email)) {
    errors.email = "Please enter a valid email address.";
    isValid = false;
  }

  if (!password) {
    errors.password = "Password is required.";
    isValid = false;
  }

  return { valid: isValid, errors };
};

const SigninPage: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [emailError, setEmailError] = useState("");
  const signin = useSignin();
  const dispatch = useDispatch();
  const navigate = useEnhancedNavigate(); // Use the enhanced navigate function

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleContinue = async (event: FormEvent) => {
    event.preventDefault();
    const validation = validateSignInForm(email, showPasswordField ? password : "");
    setEmailError(validation.errors.email);
    if (!validation.errors.email && !showPasswordField) {
      setShowPasswordField(true);
      return;
    }
    else {
      setPasswordError(validation.errors.password);
    }
    if (!validation.valid) return;
    try {
      await signin(email, password, () => { }, true);

      dispatch(showNotification({ message: "Sign In Successful!", severity: "success", horizontal: "right" }));
    }
    catch (error) {
      console.log({ error });
      dispatch(showNotification({ message: error.code.replace("auth/", "").replaceAll("-", " "), severity: "error", horizontal: "right" }));
    }
  };
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-white text-mui-black-87 overflow-hidden">
      {/* Header Section with Logo and Text */}
      <div className="flex items-center justify-center pt-12 mb-24 cursor-pointer" onClick={handleLogoClick}>
        <img src={TradeLogo} alt="TradeLogo" className="h-8 mr-2" />
        <span className="text-[20px] font-bold">
          TradeGPT
        </span>
      </div>
      <div className="flex justify-center h-full">
        <Box>
          <div className="w-[550px] min-h-[534px] relative bg-white rounded-[10px]">
            <div className="flex flex-col justify-start justify-center items-center">
              <span className="text-center text-[34px] leading-[42px]">
                Welcome Back
              </span>
            </div>
            <form onSubmit={handleContinue} className="pt-8 flex flex-col justify-start items-center gap-4">
              <TextField
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Email"
                variant="outlined"
                className="w-[340px] mb-4"
                error={!!emailError}
                helperText={emailError}
              />
              {showPasswordField && (
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="w-[340px] mb-4"
                  error={!!passwordError}
                  helperText={passwordError}
                />
              )}
              <Button
                type="submit"
                variant="contained"
                className="w-[340px] h-[50px] text-white text-[15px] font-medium leading-[26px] !bg-TGPT-secondary-dark !normal-case"
              >
                Continue
              </Button>
              {/* Sign-up link */}
              <div className="flex flex-col justify-start items-center gap-4">
                <Typography variant="body1" className="text-center">
                  Don’t have an account?
                  {" "}
                  <span onClick={handleSignUp} className="cursor-pointer text-[#1269ec] hover:underline">
                    Sign up now!
                  </span>
                </Typography>
              </div>
              {/* Divider with "OR" text */}
              <SocialBar />
            </form>
          </div>
        </Box>
      </div>
      <footer className="flex justify-center items-center mt-auto p-6">
        <span className=" text-gray-400 hover:underline">
          <Link href="/policies/service-terms" color="inherit" underline="none">
            Terms of Service
          </Link>
        </span>
        <div className="px-4 text-black">
          |
        </div>
        <span className=" text-gray-400 hover:underline">
          <Link href="/policies/privacy-policy" color="inherit" underline="none">
            Privacy Policy
          </Link>
        </span>
        <div className="px-4 text-black">
          |
        </div>
        <span className=" text-gray-400 hover:underline">
          <Link href="/policies/legal-disclaimer" color="inherit" underline="none">
            Legal Disclaimer
          </Link>
        </span>
      </footer>
    </div>
  );
};

export default SigninPage;
