import { useQuery } from "@tanstack/react-query";
import { fetchLatestNews, fetchAllNewsForTicker } from "../api/fetchNews";

export const useFetchLatestNews = (tickers: string[] | null) => {
  return useQuery({
    queryKey: ["latestNews", tickers || []],
    queryFn: () => fetchLatestNews(tickers || []),
    staleTime: 300000,
  });
};

export const useFetchAllNewsForTicker = (ticker: string) => {
  return useQuery({
    queryKey: ["pastNews", ticker],
    queryFn: () => fetchAllNewsForTicker(ticker),
    enabled: !!ticker,
    staleTime: 300000,
  });
};
