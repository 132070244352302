import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, Typography, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import "./ChangeLogsPage.scss";
import classNames from "classnames";
import Footer from "./Plans/Footer";

const ChangeLogsPage = () => {
  const changeLogs = useSelector((state: { changeLogs }) => state.changeLogs.logs);
  const isLoading = useSelector((state: { changeLogs }) => state.changeLogs.isLoading);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);

  const [activeId, setActiveId] = useState("");
  const isManualNavigation = useRef(false); // to differentiate if user is scrolling or navigating through links
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // to track if a section is fully visible in the list
  const mainContentRef = useRef<HTMLDivElement>(null);// to get dimensions of main scrollable container
  const sideScrollerRef = useRef(null);

  const scrollToLog = (version: string) => {
    const element = document.getElementById(version);
    if (element && mainContentRef.current) {
      mainContentRef.current.scrollTop = element.offsetTop - mainContentRef.current.offsetTop;
    }
  };

  // auto scrolling to log if hash is detected in URL
  useEffect(() => {
    const handleHashChange = () => {
      if (isManualNavigation.current) return;
      const hash = window.location.hash.replace("#", "");

      const isValidHash = changeLogs.some(log => log.version === hash);

      if (isValidHash) {
        setActiveId(hash);
        scrollToLog(hash);
      }
      else {
        window.history.replaceState({}, "", window.location.pathname);
        setActiveId(changeLogs[0]?.version);
        scrollToLog(changeLogs[0]?.version);
      }
    };

    if (changeLogs.length > 0) {
      handleHashChange();
    }

    // listen for browser back/forward navigation
    window.addEventListener("popstate", handleHashChange);

    return () => {
      window.removeEventListener("popstate", handleHashChange);
    };
  }, [changeLogs]);

  const handleLinkClick = (version: string) => {
    if (!version) return;
    isManualNavigation.current = true;
    setActiveId(version);
    window.history.pushState({}, "", `#${version}`);

    requestAnimationFrame(() => {
      scrollToLog(version); // perform the scroll after the state update
    });

    const resetManualNavigation = () => {
      isManualNavigation.current = false;
    };

    // to ensure the scroll operation is fully completed before resetting flag
    requestAnimationFrame(() => requestAnimationFrame(resetManualNavigation));
  };

  // section is marked as active only if it entirely inside the container AND is closest to the top of the list
  const updateActiveSection = useCallback(() => {
    if (isManualNavigation.current || !(mainContentRef.current ?? false)) return;

    const containerRect = mainContentRef.current.getBoundingClientRect();
    let newActiveId = activeId;
    let minTop = Infinity;

    for (const section of sectionRefs.current) {
      if (!section) continue;

      const sectionRect = section.getBoundingClientRect();
      const sectionTop = sectionRect.top - containerRect.top;
      const sectionBottom = sectionRect.bottom - containerRect.top;

      if (sectionTop >= 0 && sectionBottom <= containerRect.height) {
        if (sectionTop < minTop) {
          minTop = sectionTop;
          newActiveId = section.getAttribute("data-id")!;
        }
      }
    }

    if (newActiveId !== activeId) {
      setActiveId(newActiveId);
    }
  }, [activeId]);

  const handleScroll = useCallback(() => {
    requestAnimationFrame(updateActiveSection);
  }, [updateActiveSection]);

  useEffect(() => {
    const container = mainContentRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [activeId, handleScroll]);

  // sync active link in sidebar menu
  useEffect(() => {
    if (!activeId || !sideScrollerRef.current) return;
    const activeElement = document.getElementById(`link-${activeId}`);
    if (activeElement) {
      sideScrollerRef.current.scrollTo({
        top: activeElement.offsetTop - sideScrollerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [activeId]);

  return (
    <div className="h-screen w-full pt-16 text-mui-black-87 dark:text-white">
      <div className="flex flex-col w-full max-h-full xl:w-[960px] mx-auto">
        <div className="mt-4">
          <Typography variant="h4" fontWeight={700}>Release Notes</Typography>
        </div>
        <div className="mt-2 mb-8 body1 text-mui-black-60 dark:text-mui-white-70">
          <Typography>A changelog of the latest updates for TradeGPT</Typography>
        </div>
        <div className="flex flex-1 overflow-y-auto divide-x gap-8">
          <div ref={mainContentRef} className="flex flex-2 flex-col scrollable-section gap-8 p-4">
            {
              isLoading
                ? (
                    <>
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={100} />
                    </>
                  )
                : changeLogs.map((log, index) => {
                  return (
                    <div
                      id={log.version}
                      key={log.version}
                      data-id={log.version}
                      ref={el => (sectionRefs.current[index] = el)}
                      className="section"
                    >
                      <div className="flex">
                        <Typography variant="h6" fontWeight="medium">{log.title}</Typography>
                      </div>
                      <div className="opacity-50 text-mui-black-60 dark:text-mui-white-70">
                        <Typography variant="caption">
                          {moment.utc(log.date).format("MMM D, YYYY")}
                          {" "}
                          • v
                          {log.version}
                        </Typography>
                      </div>
                      <div className="mt-2">
                        <ReactMarkdown className="markdown-body change-log-markdown body1">
                          {log.description}
                        </ReactMarkdown>
                      </div>
                    </div>
                  );
                },
                )
            }
            <div className="min-h-72 flex items-center justify-center">
              <Footer />
            </div>
          </div>
          <div ref={sideScrollerRef} className="hidden md:flex flex-col gap-4 flex-1 mt-1 scrollable-section pl-2 pb-2 overflow-y-auto">
            {
              isLoading
                ? (
                    <>
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                      <Skeleton sx={{ bgcolor: isDarkMode ? "grey.200" : "#e0e0e0" }} height={30} />
                    </>
                  )
                : changeLogs.map((log) => {
                  return (
                    <Link
                      key={`link-${log.version}`}
                      id={`link-${log.version}`}
                      color="inherit"
                      variant="body2"
                      underline="none"
                      className={classNames(
                        "flex flex-col cursor-pointer px-4 py-1.5 hover:bg-mui-gray-04 dark:hover:bg-mui-light-gray-08 hover:rounded-[4px] hover:text-transform-none gap-1",
                        { "bg-mui-gray-08 dark:bg-mui-light-gray-16 rounded-[4px]": activeId === log.version },
                      )}
                      onClick={() => handleLinkClick(log.version)}
                    >
                      <p className="body1">{log.title}</p>
                      <p className="text-[13px] text-mui-black-60 dark:text-mui-white-70">{moment.utc(log.date).format("MMM D, YYYY")}</p>
                    </Link>
                  );
                },
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeLogsPage;
