import { NewsSummaryAnimated } from "../Icons/NewsSummaryAnimated";
import { updateSelectedTickerProps } from "../../redux/slices/newsSummarySlice";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useFetchLatestNews } from "./hooks";
import { useMemo } from "react";
import { Chip, CircularProgress, Divider, Tooltip } from "@mui/material";
import { useResizable } from "../../hooks/useResizable";
import ResizableIcon from "../Shared/ResizableIcon";
import { PANELS } from "../Watchlist/constants";
import classNames from "classnames";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";

const TodaySummarySection = ({ height, toggleHeights, isNewsListExpanded, resizeAnimation }: { height: number; toggleHeights: (string) => void; isNewsListExpanded: boolean; resizeAnimation?: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    tickers,
  } = useSelector((state: { watchlist }) => state.watchlist);

  const memoizedTickers = useMemo(() => tickers.map(ticker => ticker.ticker), [tickers]);

  const { data: latestSummaries, isLoading: isNewsLoading, error: fetchNewsError } = useFetchLatestNews(memoizedTickers);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useResizable();

  return (
    <div
      className={classNames(
        "shadow-elevation-custom-1 flex flex-col px-2 relative box-border rounded-lg overflow-hidden",
        "bg-white dark:bg-background/paper-elevation-4",
        resizeAnimation,
      )}
      style={{ height: `${height}px`, minHeight: "66px" }}
    >
      <div className={`p-1 py-4 flex gap-x-2 items-center font-semibold ${!isNewsListExpanded && "cursor-pointer"}`} onClick={() => !isNewsListExpanded && toggleHeights(PANELS.TODAYSUMMARY)}>
        <NewsSummaryAnimated text="Today's News Highlight" bgColor="rgba(39, 39, 39, 1)" />
        {memoizedTickers.length === 0 && (
          <Tooltip
            placement="top"
            arrow
            title={<p className="text-center text-xs">Showing top news summaries. Personalize by adding tickers to your watchlist.</p>}
            classes={{
              tooltip: "!max-w-[210px]",
            }}
          >
            <Chip className="dark:!bg-mui-white-12 dark:!text-mui-white-38 !text-mui-black-38 font-medium !h-[20px]" label="Suggested" />
          </Tooltip>
        )}
      </div>

      <div
        className="h-full flex flex-col overflow-y-auto watchlist-scroller box-content me-[-4px] pe-[4px]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {(!latestSummaries || latestSummaries?.length === 0)
        && (
          <div className="h-full flex items-center justify-center">
            {(isNewsLoading) && <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={16}></CircularProgress>}
            {fetchNewsError && <p className="body2 text-mui-black-60 dark:text-mui-white-70">Error fetching news. Please try again later</p>}
            {(latestSummaries?.length === 0) && (
              <div className="justify-center items-center flex flex-col gap-y-6">
                <AutoAwesomeRoundedIcon className="text-tgpt-secondary-states-focus !h-16 !w-16" />
                <p className="body2 text-center text-mui-black-60 dark:text-mui-white-70">News summaries are not available due to insufficient key events.</p>
              </div>
            )}
          </div>
        )}

        {!isNewsLoading && latestSummaries?.map(({ agg_id = null, ticker, summarized_headline }, index) => (
          <div key={ticker}>
            <div
              className={classNames(
                "flex flex-col gap-y-1 cursor-pointer mb-2",
                { "mt-2": index !== 0 },
              )}
              onClick={() => {
                dispatch(updateSelectedTickerProps({
                  ticker,
                  isExpandKeyEvents: true,
                  latestSummaryId: agg_id,
                }));
              }}
            >
              <div
                className="rounded p-4 hover:bg-gradient-blue-deepOrange-states-hover"
              >
                <p className="body1 font-semibold">{ticker}</p>
                <p className="body2 text-mui-black-60 dark:text-mui-white-70">
                  {summarized_headline}
                </p>
              </div>
            </div>
            <Divider className="w-72 !mx-auto" />
          </div>
        ))}
        {isHovered && (
          <ResizableIcon onClick={() => toggleHeights(PANELS.TODAYSUMMARY)} isExpanded={isNewsListExpanded} />
        )}
      </div>
    </div>
  );
};
export default TodaySummarySection;
