import { NewsSummaryStatic } from "../Icons/NewsSummaryAnimated";
import { Avatar, AvatarGroup, Divider } from "@mui/material";
import KeyEvent from "./KeyEvent";
import { useSelector } from "react-redux";
import { Summary } from "../../assets/interfaces/interfaces";
import { useState } from "react";
import { daysSince, getSourceLogos, isWithinLastDay } from "../../helpers";
import classNames from "classnames";

type SummaryCardType = {
  isLatest?: boolean;
  summaryItem?: Summary;
};
const SummaryCard = ({ isLatest = true, summaryItem }: SummaryCardType) => {
  const {
    selectedTickerProps,
  } = useSelector((state: { newsSummary }) => state.newsSummary);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);

  const sourceLogos = getSourceLogos(summaryItem?.events);

  const [isExpanded, setIsExpanded] = useState((isLatest && selectedTickerProps.isExpandKeyEvents) ? true : false);
  const isTodayNews = isWithinLastDay(summaryItem.create_timestamp);
  return (
    <div
      className={classNames(
        "flex flex-col p-4 rounded-lg",
        {
          "bg-gradient-blue-deepOrange-states-hover": isTodayNews,
          "bg-tgpt-secondary-accentLight hover:bg-tgpt-secondary-states-hover": !isTodayNews,
        },
      )}
    >
      {isTodayNews && isLatest
        ? (
            <div className="pb-4 font-semibold ms-[-4px]">
              <NewsSummaryStatic
                text="Today's News Highlight"
                startColor={isDarkMode ? "rgba(128, 186, 248, 1)" : "rgba(29, 173, 255, 1)"}
                endColor={isDarkMode ? "rgba(241, 165, 136, 1)" : "rgba(255, 112, 67, 1)"}
                width={210}
                height={30}
                fontSize={14}
              />
            </div>
          )
        : (
            <p className="caption text-mui-black-60 dark:text-mui-white-70 pb-2">
              {daysSince(summaryItem.create_timestamp)}
              d ago
            </p>
          )}

      <p className="body1">{summaryItem?.summarized_headline}</p>
      <div className="flex gap-x-2 pt-4 items-center cursor-pointer" onClick={() => setIsExpanded(prev => !prev)}>
        <p className="caption dark:text-mui-white-70">{isExpanded ? "Hide key events" : "Show key events" }</p>
        <Divider className="flex-grow" />
        <AvatarGroup max={4} spacing={5}>
          {sourceLogos.slice(0, 4).map(logoSrc => (
            <Avatar
              key={logoSrc}
              className="!w-[18px] !h-[18px] !border-0"
              alt="News Source Logo"
              src={logoSrc}
              variant="circular"
            />
          ))}
        </AvatarGroup>
      </div>
      <section
        className={`flex flex-col overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? "opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        {summaryItem?.events?.map((keyEvent, index) => (
          <div
            key={keyEvent.news_url}
            className={`transition-all duration-500 ${
              isExpanded
                ? "opacity-100 translate-y-0 pt-4"
                : "opacity-0 -translate-y-4"
            }`}
            style={{ transitionDelay: `${index * 100}ms` }}
          >
            <KeyEvent keyEvent={keyEvent} />
          </div>
        ))}
      </section>
    </div>
  );
};
export default SummaryCard;
