import { List, ListItem, ListItemText } from "@mui/material";
import TermsAndPoliciesLayout from "./components/TermsAndPoliciesLayout";
import withScrollToTop from "../../helpers/ScrollToTop";

function PrivacyPolicy() {
  return (
    <TermsAndPoliciesLayout type="privacy" headerTitle="Privacy Policy">
      <p className="font-bold">Effective Date:17 March 2025</p>
      <br />
      <br />
      <p className="font-bold text-[28px]">1. Introduction</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Welcome to the Privacy Policy for TradeGPT. This document serves as a
                legally binding agreement between you, the user, and TA Fintech Inc, the
                entity responsible for the operation and administration of TradeGPT. This
                Privacy Policy outlines the way we collect, utilize, disclose, and
                safeguard your information when you engage with our chatbot. By accessing
                or using TradeGPT, you expressly consent to the terms set forth herein.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">2. Scope and Applicability</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                This Privacy Policy applies to personal information we collect through TradeGPT. It also applies to
                information shared with us by third parties when you authorize them to do so.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We primarily operate in the U.S.. If you use TradeGPT’s services from outside the U.S., please note that
                your data may be transferred to and stored on servers within the U.S., and your information will be handled
                according to this Privacy Policy and U.S. law.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">3. Information We Collect</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We may collect both “voluntarily provided” and “automatically collected” information:
              </p>
            )}
          />
        </ListItem>
      </List>
      <p className="font-bold text-[24px]">A. Voluntarily Provided Information</p>
      <List sx={{ paddingBottom: "0" }}>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Some voluntarily provided information that we collect includes:
              </p>
            )}
          />
        </ListItem>
      </List>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Account Registration:
                </span>
                {" "}
                When you create an account or contact us, we may request your name,
                email, phone number, mailing address, and/or other relevant details.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Financial Information:
                </span>
                {" "}
                If you choose to link external financial accounts (e.g., bank or brokerage
                accounts), you may provide us with access to account balances, transaction data, and related
                financial details.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  User-Generated Content:
                </span>
                {" "}
                Any text, images, videos, or other materials that you upload into our
                chatbot.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Customer Support Communications:
                </span>
                {" "}
                Information you choose to share when contacting our support team.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[24px]">B. Automatically Collected Information</p>
      <List sx={{ paddingBottom: "0" }}>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Some automatically collected information that we collect includes:
              </p>
            )}
          />
        </ListItem>
      </List>
      <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Log Data:
                </span>
                {" "}
                We may automatically collect IP addresses, browser types, device types, pages visited,
                date/time stamps, and time spent on each page.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Device Data:
                </span>
                {" "}
                We may collect information about your device’s operating system, geo-location
                (where applicable), unique device identifiers, and crash data.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Error & Diagnostic Information:
                </span>
                {" "}
                If errors occur, we may collect data about the issue, your device
                specifications, and other related technical information.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">4. Cookies and Other Tracking Technologies</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We use cookies, web beacons, and similar technologies to improve your experience, analyze website usage,
                and tailor content to user preferences. Most web browsers automatically accept cookies; however, you can
                modify your browser settings to decline cookies if you prefer. Note that disabling cookies may affect the
                functionality of our Services.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Those providers may also employ cookies or similar technologies if you use third-party integrations or links
                (e.g., payment processors and analytics services). We encourage you to review their respective privacy
                notices.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">5. How We Use Your Information</p>
      <List sx={{ paddingBottom: "0" }}>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We use the information we collect for various purposes, including but not limited to:
              </p>
            )}
          />
        </ListItem>
      </List>
      <List sx={{ listStyleType: "decimal", paddingLeft: "36px" }}>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Providing Services:
                </span>
                {" "}
                We create and manage your account, deliver core features (such as
                personalized financial alerts), and process transactions.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Customization:
                </span>
                {" "}
                Personalize your user experience and improve our websites, applications, and
                communication strategies.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Customer Support:
                </span>
                {" "}
                Responding to inquiries, resolving technical issues, and addressing feedback.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Analytics & Research:
                </span>
                {" "}
                We analyze usage trends, diagnose technical problems, and enhance our
                Services based on aggregated insights.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Marketing & Communication:
                </span>
                {" "}
                We will send promotional materials, updates, and offers, subject
                to your marketing preferences.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                <span className="font-bold">
                  Legal Compliance & Enforcement:
                </span>
                {" "}
                Complying with applicable laws, regulations, or other legal
                obligations, and enforcing our Terms of Use.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">6. How We Share and Disclose Information</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We do not rent or sell your personal information. However, we may share data under the following
                circumstances:
              </p>
            )}
          />
          <List sx={{ listStyleType: "decimal", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Within Our Corporate Group:
                    </span>
                    {" "}
                    We may share information with our subsidiaries, affiliates, or
                    parent company for operational or administrative purposes.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Service Providers & Partners:
                    </span>
                    {" "}
                    We engage reputable third-party vendors (e.g., payment
                    processors, hosting providers, analytics tools) to perform services on our behalf. These entities are
                    contractually obligated to protect your data.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Legal or Regulatory Requirements:
                    </span>
                    {" "}
                    We may disclose information to courts, law enforcement, or
                    regulatory agencies if required by law, subpoena, or other legal process.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Protecting Rights & Safety:
                    </span>
                    {" "}
                    We may disclose information if we believe it is necessary to protect
                    our rights, property, safety, or that of our users or others.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      With Your Consent:
                    </span>
                    {" "}
                    We will share information in any other way you direct or consent to at the
                    time of collection or sharing.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                If you choose to link external accounts, you authorize us to receive and share information with these third
                parties. We recommend reviewing their privacy policies, as their data practices may differ from ours
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">7. Data Security</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We maintain administrative, technical, and physical safeguards designed to protect your personal
                information against accidental, unlawful, or unauthorized access, use, alteration, or disclosure. These
                measures include, but are not limited to:
              </p>
            )}
          />
          <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Encryption of sensitive data (e.g., financial account information).
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Access controls limit employee or contractor access to personal information.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    Periodic security assessments and ongoing monitoring of our systems.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Although we strive to protect your information, no security method is 100% foolproof. If we become aware
                of a data breach that compromises your personal information, we will notify you and the relevant authorities
                as required by applicable law. Depending on the situation, we may notify you by email, posting a notice on
                our site, or other channels.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">8. Data Retention</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We retain your personal information for as long as it is necessary to fulfill the purposes outlined in this
                Privacy Policy unless a longer retention period is required or permitted by law. For instance:
              </p>
            )}
          />
          <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Account Data:
                    </span>
                    {" "}
                    It is retained while your account is active and for a reasonable period thereafter if
                    needed for legal, regulatory, or legitimate business purposes.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Financial Data:
                    </span>
                    {" "}
                    It is stored as long as your linked accounts are active or necessary for transactional
                    or compliance records.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Marketing & Analytics:
                    </span>
                    {" "}
                    Aggregated or anonymized data may be retained indefinitely for trend
                    analysis and statistical purposes.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Once no longer needed, we will securely delete or anonymize your data in accordance with our retention
                policies.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">9. Your Rights & Choices</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Depending on where you live and applicable laws, you may have rights regarding your personal
                information, including the right to:
              </p>
            )}
          />
          <List sx={{ listStyleType: "disc", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Access and Portability:
                    </span>
                    {" "}
                    Request details or a copy of the personal information we hold about you.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Correction:
                    </span>
                    {" "}
                    Ask us to correct any inaccuracies or outdated information.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Deletion:
                    </span>
                    {" "}
                    Request deletion of your personal information, subject to certain legal exceptions.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Opt-Out of Marketing:
                    </span>
                    {" "}
                    Withdraw your consent to receive marketing communications at any time.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Nondiscrimination:
                    </span>
                    {" "}
                    We will not discriminate against you for exercising your privacy rights.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                To exercise your right to deletion, please use our
                {" "}
                <span className="font-bold">Take Down Procedure</span>
                . To exercise all the other rights,
                please contact us at
                {" "}
                <a
                  className="underline text-[#007BFF]"
                  href="mailto:support@tradealgo.com"
                >
                  support@tradealgo.com
                </a>
                .
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We may request additional information or take steps to verify your identity before completing your request.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">10. Additional Disclosures for California Residents</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0", paddingBottom: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                In addition to the rights outlined above, California residents may have further privacy rights under the
                California Consumer Privacy Act (CCPA) or other state legislation. These may include:
              </p>
            )}
          />
          <List sx={{ listStyleType: "decimal", paddingLeft: "36px" }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Right to Know:
                    </span>
                    {" "}
                    The categories and specific pieces of personal information collected, used,
                    disclosed, or sold (if applicable).
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Right to Delete:
                    </span>
                    {" "}
                    The right to request deletion of personal information, subject to legal exceptions.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Right to Opt-Out of “Sale”:
                    </span>
                    {" "}
                    If we ever “sell” personal information as defined by CCPA,
                    California residents would have the right to opt-out.
                  </p>
                )}
              />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText
                primary={(
                  <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                    <span className="font-bold">
                      Right to Non-Discrimination:
                    </span>
                    {" "}
                    You will not receive discriminatory treatment for exercising any
                    of these rights.
                  </p>
                )}
              />
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                You may submit requests by contacting us at
                {" "}
                <a
                  className="underline text-[#007BFF]"
                  href="mailto:support@tradealgo.com"
                >
                  support@tradealgo.com
                </a>
                . Please include “California Privacy Rights Request” in your message and describe the specific request.
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                If you have questions regarding how we handle personal information under California law, you may also
                contact us at the same email address.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">11. Children’s Privacy</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Our Services are not intended for individuals under the age of 13, and we do not knowingly collect personal
                information from children under 13. If you believe we have inadvertently collected such information, please
                contact us at
                {" "}
                <a
                  className="underline text-[#007BFF]"
                  href="mailto:support@tradealgo.com"
                >
                  support@tradealgo.com
                </a>
                , and we will promptly take steps to delete it.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">12. Do Not Track</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                Many web browsers offer a “Do Not Track” (DNT) setting or similar mechanism that can send a signal to
                websites indicating a user does not wish to be tracked. At this time, we do not respond to DNT browser
                signals. We will, however, continue to review evolving standards and may update our practices if DNT
                becomes a widely accepted standard.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">13. International Data Transfers</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                If you access or use our Services from outside the United States, your information may be transferred to
                and processed in the U.S. or other countries where we or our service providers maintain facilities. Those
                countries may not have data protection laws equivalent to those in your country of residence. We will take
                measures to ensure that your personal information is protected in accordance with this Privacy Policy and
                applicable law.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">14. Business Transfers</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                If we (or substantially all of our assets) are acquired by a third party or in the unlikely event we go out of
                business or enter bankruptcy, user information (including personal data) may be transferred or acquired by
                a third party as part of that transaction. Any acquiring party will be bound by the terms of this Privacy
                Policy or substantially similar terms regarding the continued use of your data.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">15. Updates to This Policy</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We may modify or update this Privacy Policy from time to time to reflect changes in our data practices or
                legal requirements. When we make material changes, we will update the “Last Updated” date at the top of
                this page. We will notify you via email or other prominent notice on our website where legally required.
                Your continued use of our Services after any such update constitutes your acceptance of the revised Privacy
                Policy.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">16. Contact Us</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please
                contact us at
                {" "}
                <a
                  className="underline text-[#007BFF]"
                  href="mailto:support@tradealgo.com"
                >
                  support@tradealgo.com
                </a>
                .
              </p>
            )}
          />
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                We are committed to resolving any privacy-related issues and appreciate the opportunity to address your
                concerns.
              </p>
            )}
          />
        </ListItem>
      </List>
      <br />
      <p className="font-bold text-[28px]">Thank You for Trusting TradeGPT</p>
      <List>
        <ListItem sx={{ display: "list-item", paddingX: "0" }}>
          <ListItemText
            primary={(
              <p className="font-['Inter'] font-normal text-lg text-[#1C1C1E] leading-[32px]">
                By using our Services, you agree to the collection, use, and sharing of your information in accordance with
                this Privacy Policy. We value your privacy and will continue to make every effort to protect it.
              </p>
            )}
          />
        </ListItem>
      </List>
    </TermsAndPoliciesLayout>
  );
};

export default withScrollToTop(PrivacyPolicy);
